import Component from "../lib/components/component";
import closest from "../lib/dom/closest";
import {add, find, remove} from "../lib/array";

const parseInput = (el) => {
	if (el) {
		const optionEl = closest(el, "[data-hook~=\"option\"]");
		const labelEl = optionEl && optionEl.querySelector("[data-hook~=\"option-label\"]");
		const label = labelEl && labelEl.textContent || "";
		const value = el.value;
		return {
			label,
			value
		};
	}
	return null;
};

export default Component.extend({

	props: {
		isExpanded: "boolean",
		label: "string",
		name: "string",
		onValueChange: "function",
		onToggle: "function",
		value: "array"
	},

	derived: {
		currentLabel: {
			deps: ["value", "label"],
			fn() {
				if (this.value === undefined || this.value.length === 0) {
					return this.label;
				}
				const firstLabel = this.value[0] && this.value[0].label;
				const suffix = (this.value.length > 1 ? " (+" + (this.value.length - 1) + ")" : "");
				return firstLabel + suffix;
			}
		},
		hasValue: {
			deps: ["value"],
			fn() {
				return (this.value && this.value.length > 0);
			}
		}
	},

	bindings: {
		currentLabel: {
			type: "text",
			hook: "filter-group-label-text"
		},
		hasValue: {
			type: "booleanClass"
		},
		isExpanded: {
			type: "booleanClass"
		}
	},

	events: {
		"click [data-hook~=\"filter-group-label\"]": "_onLabelClick",
		"click input[type=\"checkbox\"]": "_onInputClick"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.on("change:value", this._handleValueChange);
		this.parseProps();
	},

	parseProps() {
		const label = this.el && this.el.getAttribute("data-label");
		const name = this.el && this.el.getAttribute("data-name");
		const elements = this.el && this.queryAll("input");
		if (label) {
			this.label = label;
		}
		if (name) {
			this.name = name;
		}
		if (elements && elements.length) {
			for (let i = 0; i < elements.length; i++) {
				const inputEl = elements[i];
				if (inputEl.checked) {
					this.select(parseInput(inputEl));
				}
			}
		}
		return this;
	},

	select(obj) {
		if (!this.value || !find(this.value, item => item.value === obj.value)) {
			this.value = add(this.value, obj);
		}
		return this;
	},

	unselect(obj) {
		const existing = this.value && find(this.value, item => item.value === obj.value);
		if (existing) {
			this.value = remove(this.value, existing);
		}
		return this;
	},

	_handleValueChange(target, newValue) {
		if (this.onValueChange) {
			this.onValueChange(this.name, newValue);
		}
	},

	_onInputClick(e) {
		const target = e.delegateTarget || e.currentTarget;
		this[target.checked ? "select" : "unselect"](parseInput(target));
	},

	_onLabelClick(e) {
		if (this.onToggle) {
			e.preventDefault();
			this.onToggle(this);
		}
	}

});
