import Component from "../components/component";

export default Component.extend({

	props: {
		onPlay: "function"
	},

	events: {
		"click": "_onClick"
	},

	_onClick(e) {
		e.preventDefault();
		if (this.onPlay) {
			this.onPlay();
		}
	}

});
