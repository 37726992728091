var after = require("after-transition");
var assign = require("lodash/assign");
var prefix = require("vendor-prefix");
var has3d = require("../features/has-translate3d");

var getComputedStyle = window && (window.getComputedStyle || window.currentStyle);
var translateProperty = "translate" + (has3d ? "3d(" : "(");

function getProperty(el, prop) {
	return getComputedStyle(el).getPropertyValue(prop);
}

function removeProperty(el, prop) {
	return el.style.removeProperty(prop);
}

function parseTime(s) {
	return typeof s === "string" ? parseFloat(s) * 1000 : s;
}

function setProperty(el, prop, value, priority) {
	el.style.setProperty(prop, value, priority || "");
}

function Transition(el) {
	var that = this;

	that.el = el;
	that._declarations = {};
	that._delay = 0;
	that._duration = 500;
	that._properties = [];
	that._timingFunction = "ease";
	that._transforms = [];

	that.promise = new Promise(function (resolve, reject) {

		that.end = function (err) {
			if (err) {
				reject(err);
			} else {
				resolve(that);
			}
			return that;
		};
	});
}

assign(Transition.prototype, {

	current: function (prop) {
		return getProperty(this.el, prefix.dash(prop));
	},

	delay: function (s) {
		this._transitionDelay = parseTime(s);
		return this;
	},

	duration: function (s) {
		this._duration = parseTime(s);
		return this;
	},

	ease: function (timingFunction) {
		this._timingFunction = timingFunction;
		return this;
	},

	pause: function () {
		var props = Object.keys(this._declarations);
		var computedStyle = getComputedStyle(this.el);
		for (var i = 0, l = props.length; i < l; i++) {
			setProperty(this.el, props[i], computedStyle.getPropertyValue(props[i]));
		}
		return this.reset();
	},

	property: function (prop) {
		if (this._properties.indexOf(prop) === -1) {
			this._properties.push(prop);
		}
		return this;
	},

	reset: function () {
		removeProperty(this.el, prefix.dash("transition-delay"));
		removeProperty(this.el, prefix.dash("transition-duration"));
		removeProperty(this.el, prefix.dash("transition-property"));
		removeProperty(this.el, prefix.dash("transition-timing-function"));
		return this;
	},

	set: function (prop, value) {
		this._declarations[prefix.dash(prop)] = value;
		return this;
	},

	start: function () {
		var that = this;
		var prop;

		if (that._delay) {
			setProperty(that.el, prefix.dash("transition-delay"), that._delay + "ms");
		}
		if (that._duration) {
			setProperty(that.el, prefix.dash("transition-duration"), that._duration + "ms");
		}
		if (that._properties.length) {
			setProperty(that.el, prefix.dash("transition-property"), that._properties.join(", "));
		}
		if (that._timingFunction) {
			setProperty(that.el, prefix.dash("transition-timing-function"), that._timingFunction);
		}

		if (that._transforms.length) {
			that.set("transform", that._transforms.join(" "));
		}

		for (prop in that._declarations) {
			if (that._declarations.hasOwnProperty(prop)) {
				setProperty(that.el, prop, that._declarations[prop]);
			}
		}

		after.once(that.el, function () {
			that.reset().end();
		});

		return that.promise;
	},

	stop: function (reason) {
		return this.pause().end(reason);
	},

	transform: function (transform) {
		this._transforms.push(transform);
		return this;
	},

	translate: function (x, y, z) {
		var values = [x || 0, y || 0];
		if (has3d) {
			values.push(z || 0);
		}
		return this.transform(translateProperty + values.join(", ") + ")");
	},

	translateX: function (value) {
		return this.translate(value, 0, 0);
	},

	translateY: function (value) {
		return this.translate(0, value, 0);
	},

	translateZ: function (value) {
		return this.translate(0, 0, value);
	},

	unset: function (prop) {
		var prefixed = prefix.dash(prop);
		if (typeof this._declarations[prefixed] !== "undefined") {
			removeProperty(this.el, prefixed);
			delete this._declarations[prefixed];
		}
	}

});

module.exports = function transition(el) {
	return new Transition(el);
};
