import Component from "../lib/components/component";
import Gallery from "./gallery";
import Masthead from "./masthead";
import Menu from "./menu";
import MotionManager from "../services/motion-manager";
import Page from "./page";
import Region from "../lib/components/region";
import assign from "lodash/assign";
import dom from "ampersand-dom";
import offset from "document-offset";
import parseQueryString from "qs/lib/parse";
import {scrollTop} from "../services/motion/utils";

export default Component.extend({

	props: {
		activeSection: "string",
		isBelowMenu: "boolean",
		isLoading: "boolean",
		isMenuOpen: "boolean",
		isMenuSticky: "boolean",
		isMenuVisible: "boolean",
		isOverlayOpen: "boolean",
		menuVisibleHeight: "number",
		pageTitle: "string"
	},

	derived: {
		isFullscreen: {
			deps: ["isMenuOpen", "isOverlayOpen"],
			fn() {
				return (this.isMenuOpen || this.isOverlayOpen);
			}
		}
	},

	children: {
		motionManager: MotionManager
	},

	components: {
		masthead: {
			type: Masthead,
			container: "[data-hook~=\"root-masthead\"]",
			bindings: {
				motionManager: "motionManager"
			}
		},
		menu: {
			type: Menu,
			container: "[data-hook~=\"root-menu\"]",
			bindings: {
				activeSection: "activeSection",
				motionManager: "motionManager"
			}
		},
		main: {
			type: Page,
			container: "[data-hook~=\"root-main\"]",
			bindings: {
				motionManager: "motionManager"
			}
		},
		overlay: {
			type: Region,
			hook: "root-overlay"
		}
	},

	bindings: {
		isBelowMenu: {
			type: "booleanClass"
		},
		isLoading: {
			type: "booleanClass"
		},
		isMenuOpen: {
			type: "booleanClass"
		},
		isMenuSticky: {
			type: "booleanClass"
		},
		isMenuVisible: {
			type: "booleanClass"
		},
		isOverlayOpen: {
			type: "booleanClass"
		}
	},

	events: {
		"click a[href]": "_onLinkClick",
		"click [data-hook~=\"root-up-button\"]": "_onUpButtonClick"
	},

	actions: {

		changeLanguage(language, url) {
			this.send("navigateIfHasRoute", url);
		},

		closeMenu() {
			this.isMenuOpen = false;
		},

		closeGallery() {
			this.overlay.clear();
			this.gallery = undefined;
		},

		error(err) {
			if (window.console && typeof window.console.error === "function") {
				window.console.error(err.stack || err);
			}
			this.send("trackEvent", {
				category: err.name || "Error",
				action: err.message || err,
				label: err.stack || err.fileName ? err.fileName + ":" + err.lineNumber + ":" + err.columnNumber : undefined
			});
		},

		loading(promise) {
			this.isLoading = true;
			promise.then(res => {
				this.isLoading = false;
				return res;
			}, err => {
				this.isLoading = false;
				throw err;
			});
		},

		navigateIfHasRoute(fragment) {
			this.isMenuOpen = false;
			window.location.assign(fragment);
		},

		openMenu() {
			this.isMenuOpen = true;
		},

		openGallery(slides, index) {
			const gallery = this.createComponent({
				type: Gallery,
				props: {
					collection: slides,
					index: index
				},
				bindings: {
					motionManager: "motionManager",
					onChange: "sendShowSlide",
					onClose: "sendCloseGallery",
					onNext: "sendShowNextSlide",
					onPrev: "sendShowPrevSlide"
				}
			});
			this.overlay.show(gallery);
		},

		scrollTo(newY, options) {
			this.scrollTo(0, newY, options);
		},

		showNextSlide() {
			const gallery = this.overlay.component;
			if (gallery) {
				const currentIndex = gallery.index;
				if (currentIndex < gallery.total - 1) {
					this.send("showSlide", currentIndex + 1);
				}
			}
		},

		showPrevSlide() {
			const gallery = this.overlay.component;
			if (gallery) {
				const currentIndex = gallery.index;
				if (currentIndex > 0) {
					this.send("showSlide", currentIndex - 1);
				}
			}
		},

		showSlide(newIndex) {
			const gallery = this.overlay.component;
			if (gallery) {
				gallery.index = newIndex;
			}
		},

		toggleMenu() {
			this.toggle("isMenuOpen");
		},

		trackEvent({category, action, label, value}) {
			const ga = window.ga;
			if (ga) {
				ga("send", "event", category, action, label, value);
			}
		},

		trackPageview({page}) {
			const ga = window.ga;
			if (ga && page) {
				const path = page.replace(new RegExp("^" + document.location.origin), "");
				ga("set", "page", (path.charAt(0) !== "/" ? "/" : "") + path);
				ga("send", "pageview");
			}
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);

		this._viewportTopPrev = 0;
		this._viewportWidthPrev = 0;

		this.on("change:isFullscreen", this._handleIsFullscreenChange);
		this.on("change:menuVisibleHeight", this._handleMenuVisibleHeightChange);
		//this.on("change:pageTitle", this._handlePageTitleChange);
		//this.listenTo(this.main, "hide", this._handleMainHide);
		//this.listenTo(this.main, "show", this._handleMainShow);
		this.listenTo(this.overlay, "change:component", this._handleOverlayComponentChange);
		this.motionManager.observeComponent(this);
		if (this.el) {
			this._rendered = true;
			this.motionManager.startViewportUpdates();
			this.scrollToHash();
		}
	},

	didMove(rect, data) {
		this.isBelowMenu = this.getIsBelowMenu();
		this.isMenuSticky = this.getIsMenuSticky();
		this.isMenuVisible = this.getIsMenuVisible();
		this.menuVisibleHeight = this.getMenuVisibleHeight();
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.motionManager.startViewportUpdates();
		this.scrollToHash();
		return this;
	},

	remove() {
		Component.prototype.remove.apply(this, arguments);
		this.motionManager.remove();
		return this;
	},

	scrollTo(x = 0, y = 0, { behavior = "smooth" } = {}) {
		const contentEl = this.main.component && this.main.component.el || this.el;

		if (x > 0) {
			const maxX = contentEl.offsetWidth - window.innerWidth;
			x = x < maxX ? x : maxX;
		}

		if (y > 0) {
			const maxY = contentEl.offsetHeight - window.innerHeight;
			y = y < maxY ? y : maxY;
		}

		window.scroll({ top: y, left: x, behavior });
		return this;
	},

	scrollToHash() {
		const query = parseQueryString(window.location.search.replace(/^\?/, ""));
		const jump = query && query.jump || null;
		const element = jump ? this.query("#" + jump) : null;
		if (element === null || scrollTop() > 0) {
			return this;
		}
		element.scrollIntoView({ behavior: "smooth" });
		return this;
	},

	sendCloseGallery() {
		this.send("closeGallery");
	},

	sendShowNextSlide() {
		this.send("showNextSlide");
	},

	sendShowPrevSlide() {
		this.send("showPrevSlide");
	},

	sendShowSlide(index) {
		this.send("showSlide", index);
	},

	setMenuBottom(value) {
		this._menuBottom = value;
		return this;
	},

	setMenuVisibleHeight(value) {
		if (value !== this._menuVisibleHeight) {
			this._menuVisibleHeight = value;
		}
		return this;
	},

	setIsBelowMenu(state) {
		if (state !== this._isBelowMenu) {
			this._isBelowMenu = state;
		}
		return this;
	},

	setIsMenuSticky(state) {
		if (state !== this._isMenuSticky) {
			this._isMenuSticky = state;
		}
		return this;
	},

	setIsMenuVisible(state) {
		this._isMenuVisible = state;
		return this;
	},

	getMenuBottom() {
		if (this._menuBottom === undefined) {
			const menuRect = this.getMenuRect();
			this._menuBottom = menuRect.top + menuRect.height;
		}
		return this._menuBottom;
	},

	getIsBelowMenu() {
		return this._isBelowMenu;
	},

	getIsMenuSticky() {
		return this._isMenuSticky;
	},

	getIsMenuVisible() {
		return this._isMenuVisible;
	},

	getMenuVisibleHeight() {
		return this._menuVisibleHeight;
	},

	getMenuRect(update) {
		if (this._menuRect === undefined || this._menuRect === null || update === true) {
			const menuOffset = offset(this.menu.el);
			this._menuRect = Object.assign(menuOffset, {
				height: this.menu.el.clientHeight,
				width: this.menu.el.clientWidth
			});
		}

		return this._menuRect;
	},

	willMove(rect, data) {
		const maxTop = (rect.y + rect.height) - data.viewport.height;
		const top = data.viewport.y >= 0 ? data.viewport.y > maxTop ? maxTop : data.viewport.y : 0;
		const width = data.viewport.width;

		if (!this.rendered || (top === this._viewportTopPrev && width === this._viewportWidthPrev)) {
			return;
		}

		const menuRect = this.getMenuRect(this._viewportWidthPrev !== width);
		const menuTop = menuRect.top;
		const menuHeight = menuRect.height;

		this.setIsMenuSticky(top > menuTop);
		this.setIsBelowMenu(top > menuTop + menuHeight);

		if (top < this._viewportTopPrev) {
			// Scrolling up

			if (!this.getIsMenuVisible()) {
				this.setMenuBottom(this._viewportTopPrev);
				this.setIsMenuVisible(true);
			}

			const menuBottomDelta = this.getMenuBottom() - top;
			const menuVisibleHeight = Math.min(menuHeight, menuBottomDelta);

			this.setMenuVisibleHeight(menuVisibleHeight);

			if (menuBottomDelta >= menuHeight) {
				this.setMenuBottom(top + menuHeight);
			}

			//console.log('up  ', top, this.getMenuBottom(), menuBottomDelta, menuVisibleHeight, this.getIsMenuVisible());

		} else {
			// Scrolling down
			const menuBottomDelta = this.getMenuBottom() - top;
			const menuVisibleHeight = Math.max(0, Math.min(menuHeight, menuBottomDelta));

			if (this.getIsMenuVisible() && (menuBottomDelta < 0 || menuBottomDelta > menuHeight)) {
				this.setIsMenuVisible(false);
			}

			this.setMenuVisibleHeight(menuVisibleHeight);

			//console.log('down', top, this.getMenuBottom(), menuBottomDelta, menuVisibleHeight, this.getIsMenuVisible());
		}

		this._viewportTopPrev = top;
		this._viewportWidthPrev = width;
	},

	_handleIsFullscreenChange(target, newVal) {
		if (newVal) {
			this._scrollYBeforeFullscreen = scrollTop();
		}
		dom[newVal ? "addClass" : "removeClass"](document.body, "isFullscreen");
		if (!newVal) {
			this.scrollTo(0, this._scrollYBeforeFullscreen, { behavior: "auto" });
			this._scrollYBeforeFullscreen = undefined;
		}
	},

	_handleMainHide(region, component) {
		component.unset("motionManager");
	},

	_handleMainShow(region, component) {
		component.motionManager = this.motionManager;
	},

	_handleMenuVisibleHeightChange(target, newVal) {
		if (this.rendered && this.menu && this.menu.el) {
			const menuContainer = this.menu.el.parentNode;
			const menuRect = this.getMenuRect();
			const translateY = (menuRect.height * -1) + newVal;
			menuContainer.style.setProperty("transform", "translateY(" + translateY + "px)");
		}
	},

	_handleOverlayComponentChange(target, newVal) {
		this.isOverlayOpen = !!newVal;
	},

	_handlePageTitleChange(target, newVal) {
		if (this.rendered) {
			document.title = newVal;
		}
	},

	_onLinkClick(e) {
		const target = e.delegateTarget || e.currentTarget;
		const local = (target.host === window.location.host);
		const hasTarget = target.target && target.target === "_blank";
		// if it's a plain click (no modifier keys)
		// and it's a local url, navigate internally
		if (local && !hasTarget && !e.ctrlKey && !e.shiftKey && !e.altKey &&
				!e.metaKey && !e.defaultPrevented) {
			e.preventDefault();
			this.send("navigateIfHasRoute", target.pathname + target.search);
		}
	},

	_onUpButtonClick(e) {
		e.preventDefault();
		this.send("scrollTo", 0);
	}

});
