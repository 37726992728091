var prefix = require("vendor-prefix");
var has3d = require("../features/has-translate3d");

var transformProperty = prefix("transform");
var translateProperty = "translate" + (has3d ? "3d(" : "(");

function translate(el, x, y, z) {
	var values = [x || 0, y || 0];
	if (has3d) {
		values.push(z || 0);
	}
	el.style[transformProperty] = translateProperty + values.join(", ") + ")";
}

module.exports = translate;
