import Component from "../lib/components/component";
import Track from "./track";
import TrackList from "./track-list";

export default Component.extend({

	components: {
		masterTrack: {
			type: Track,
			container: "[data-hook~=\"song-details-master\"]"
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.createVariants();
	},

	createVariants() {
		const variantsContainer = this.el && this.queryByHook("song-details-variants");
		const variantsEl = variantsContainer && variantsContainer.children[0];
		if (variantsEl) {
			return this.createComponent({
				type: TrackList,
				el: variantsEl
			}, "variants");
		}
		return null;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.createVariants();
		return this;
	},

	remove() {
		this.variants = undefined;
		return Component.prototype.remove.apply(this, arguments);
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.createVariants();
		return this;
	}

});
