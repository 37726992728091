import Component from "../lib/components/component";
import Caption from "./caption";
import StackCollapsed from "./stack-collapsed";
import {SlideCollection} from "../models/slide";

export default Component.extend({

	props: {
		activeSlide: "state",
		defaultCaption: "string",
		motionManager: "state"
	},

	derived: {
		currentCaption: {
			deps: ["activeSlide", "defaultCaption"],
			fn() {
				if (this.defaultCaption) {
					return this.defaultCaption;
				}
				return this.activeSlide ? this.activeSlide.caption : undefined;
			}
		}
	},

	collections: {
		slides: SlideCollection
	},

	components: {
		stack: {
			type: StackCollapsed,
			container: "[data-hook~=\"figure-media\"]",
			bindings: {
				motionManager: "motionManager",
				onChange: "handleStackChange"
			}
		},
		caption: {
			type: Caption,
			container: "[data-hook~=\"figure-caption\"]",
			bindings: {
				body: "currentCaption",
				credit: "activeSlide.credit"
			}
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.parseSlides();
	},

	parseSlides() {
		const container = this.el && this.query("script[type=\"application/json\"]");
		if (container) {
			try {
				const payload = JSON.parse(container.textContent);
				this.set(this.parse(payload));
			} catch (e) {
				this.send("error", e);
			}
		}
		return this;
	},

	parse(payload = {}) {
		if (payload.slides) {
			this.slides.reset(payload.slides);
			delete payload.slides;
		}
		return payload;
	},

	handleStackChange(id) {
		const activeSlide = this.slides.get(id);
		this.activeSlide = activeSlide;
	}

});
