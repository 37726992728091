import Component from "../lib/components/component";

export const travel = (rect, viewport) => {
	const bottomBound = rect.y + rect.height;
	const rectContext = rect.y < viewport.height ? bottomBound - viewport.height : rect.height;
	const context = rectContext + viewport.height;
	const travel = 1 - ((bottomBound - viewport.y) / context);
	return travel < 0 ? 0 : travel > 1 ? 1 : travel;
};

export const visibility = (rect, viewport) => {
	const context = rect.height < viewport.height ? rect.height : viewport.height;
	if (viewport.y > rect.y) {
		return 1 - (viewport.y - rect.y) / context;
	}
	if ((viewport.y + viewport.height) < (rect.y + rect.height)) {
		return ((viewport.y + viewport.height) - rect.y) / context;
	}
	return 1;
};

/**
@class MotionComponent
@extends Component
**/
export default Component.extend({

	// -- Public Properties ----------------------------------------------------

	props: {
		/**
		@property motionManager
		@type null|MotionManager
		@default null
		**/
		motionManager: "state"
	},

	// -- Lifecycle Methods ----------------------------------------------------

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		if (this.motionManager) {
			this._attachMotionManager(this.motionManager);
		}
		this.on("change:motionManager", this._handleMotionManagerChange);
	},

	// -- Public Methods -------------------------------------------------------

	/**
	@method remove
	@chainable
	**/
	remove() {
		if (this.motionManager) {
			this._detachMotionManager(this.motionManager);
		}
		return Component.prototype.remove.apply(this, arguments);
	},

	// -- Protected Methods ----------------------------------------------------

	/**
	@private
	@method _attachMotionManager
	@param {MotionManager} motionManager
	**/
	_attachMotionManager(motionManager) {
		motionManager.observeComponent(this);
		if (typeof this.attachMotionManager === "function") {
			this.attachMotionManager(motionManager);
		}
	},

	/**
	@private
	@method _detachMotionManager
	@param {MotionManager} motionManager
	**/
	_detachMotionManager(motionManager) {
		if (typeof this.detachMotionManager === "function") {
			this.detachMotionManager(motionManager);
		}
		motionManager.unobserveComponent(this);
	},

	// -- Protected Event Handlers ---------------------------------------------

	/**
	@private
	@method _handleMotionManagerChange
	@param {Component} target
	@param {MotionManager|null} motionManager
	**/
	_handleMotionManagerChange(target, newVal) {
		const prevVal = target.previous("motionManager");
		if (prevVal) {
			this._detachMotionManager(prevVal);
		}
		if (newVal) {
			this._attachMotionManager(newVal);
		}
	}

});
