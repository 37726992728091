import assign from "lodash/assign";
import loadYouTubeIframeApi from "./load-youtube-iframe-api";

const createYouTubePlayer = (el, options) => {
	let player;

	let promise = loadYouTubeIframeApi().then(YT => {
		return new Promise((resolve) => {
			const previousOnReady = options && options.events && options.events.onReady;

			const onReady = (e) => {
				resolve(e.target);
				if (previousOnReady) {
					previousOnReady(e);
				}
			};

			const events = assign({}, options && options.events, {onReady});
			const opts = assign({}, options, {events});
			new YT.Player(el, opts);
		});
	}).then((readyPlayer) => {
		player = readyPlayer;
		return readyPlayer;
	});

	return () => {
		return Promise.resolve(player ? player : promise);
	};
};

export default createYouTubePlayer;
