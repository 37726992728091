import "./styles/webfonts.scss";
import "./styles/app.scss";
import "picturefill";
import "whatwg-fetch";
import objectFitImages from "object-fit-images";
import PromisePolyfill from "promise-polyfill";
import smoothscroll from "smoothscroll-polyfill";
import Root from "./components/root";
import config from "./config";
import domReady from "domready";
import i18n from "./i18n";

window.Promise = window.Promise || PromisePolyfill;
smoothscroll.polyfill();
objectFitImages();

i18n.locale(config.locale);

domReady(() => {
	const el = document.querySelector("[data-hook~=\"root\"]");
	window.app = new Root({ el });
});
