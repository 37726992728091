var matches = require("matches-selector");

function findClosest(el, selector) {
	while (el) {
		if (matches(el, selector)) {
			return el;
		}
		el = el.parentNode;
	}
	return null;
}

module.exports = function closest(el, selector) {
	return typeof el.closest === "function" ? el.closest(selector) : findClosest(el, selector);
};
