import State from "ampersand-state";
import MotionComponent from "./motion";
import Slideshow from "./slideshow";
import GallerySlide from "./gallery-slide";
import template from "./gallery.hbs";

const GallerySlideshow = Slideshow.extend({

	props: {
		ratio: "number"
	},

	component: {
		type: GallerySlide,
		bindings: {
			ratio: "ratio"
		}
	}

});

export default MotionComponent.extend({

	props: {
		collection: "collection",
		index: "number",
		onClose: "function",
		onNext: "function",
		onPrev: "function",
		ratio: "number"
	},

	derived: {
		isFirst: {
			deps: ["index"],
			fn() {
				return (this.index === 0);
			}
		},
		isLast: {
			deps: ["index", "total"],
			fn() {
				return (this.index === this.total - 1);
			}
		},
		total: {
			deps: ["collection"],
			fn() {
				return this.collection ? this.collection.length : 0;
			}
		}
	},

	components: {
		slideshow: {
			type: GallerySlideshow,
			hook: "gallery-slideshow",
			bindings: {
				collection: "collection",
				index: "index",
				ratio: "ratio",
				onChange: "sendShowSlide"
			}
		}
	},

	bindings: {
		isFirst: {
			type: "booleanClass"
		},
		isLast: {
			type: "booleanClass"
		}
	},

	events: {
		"click [data-hook~=\"gallery-button-close\"]": "_onCloseClick",
		"click [data-hook~=\"gallery-button-next\"]": "_onNextClick",
		"click [data-hook~=\"gallery-button-prev\"]": "_onPrevClick"
	},

	template: template,

	didMove() {
		this.updateRatio();
	},

	mount() {
		MotionComponent.prototype.mount.apply(this, arguments);
		this.updateRatio();
		return this;
	},

	renderTemplate(context, template) {
		if (context instanceof State) {
			context = context.serialize({ derived: true });
		}
		return MotionComponent.prototype.renderTemplate.call(this, context, template);
	},

	render() {
		MotionComponent.prototype.render.apply(this, arguments);
		this.updateRatio();
		return this;
	},

	sendShowSlide(index) {
		this.send("showSlide", index);
	},

	updateRatio() {
		const rect = this.motionManager && this.motionManager.getRectForComponent(this);
		if (rect) {
			this.ratio = rect.height / rect.width;
		}
		return this;
	},

	_onCloseClick(e) {
		e.preventDefault();
		if (this.onClose) {
			this.onClose();
		}
	},

	_onNextClick(e) {
		e.preventDefault();
		if (this.onNext) {
			this.onNext();
		}
	},

	_onPrevClick(e) {
		e.preventDefault();
		if (this.onPrev) {
			this.onPrev();
		}
	}

});
