import Component from "../lib/components/component";
import Audio from "./audio";
import dom from "ampersand-dom";

export default Component.extend({

	props: {
		onChangePlaying: "function"
	},

	components: {
		audio: {
			type: Audio,
			selector: "audio"
		}
	},

	events: {
		"click button": "_onButtonClick"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.listenTo(this.audio, "change:isPlaying", this._handleIsPlayingChange);
	},

	pause() {
		this.audio.pause();
		return this;
	},

	play() {
		this.audio.play();
		return this;
	},

	toggleIsPlayingClass(state) {
		if (this.el) {
			dom[state ? "addClass" : "removeClass"](this.el, "isPlaying");
		}
		return this;
	},

	togglePlaying(state) {
		state = state === undefined || state === null ? !this.audio.isPlaying : state;
		this[state ? "play" : "pause"]();
		return this;
	},

	_handleIsPlayingChange(target, isPlaying) {
		this.toggleIsPlayingClass(isPlaying);
		if (this.onChangePlaying) {
			this.onChangePlaying(isPlaying);
		}
	},

	_onButtonClick(e) {
		e.preventDefault();
		this.togglePlaying();
	}

});
