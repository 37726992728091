import Component from "../lib/components/component";
import AudioPlayer from "./audio-player";

export default Component.extend({

	props: {
		isActive: "boolean"
	},

	bindings: {
		isActive: {
			type: "booleanClass"
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.createPlayer();
	},

	createPlayer() {
		const playerEl = this.el && this.queryByHook("track-player");
		if (playerEl) {
			return this.createComponent({
				type: AudioPlayer,
				el: playerEl,
				bindings: {
					onChangePlaying: "sendChangePlaying"
				}
			}, "player");
		}
		return null;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.createPlayer();
		return this;
	},

	pause() {
		if (this.player) {
			this.player.pause();
		}
		return this;
	},

	play() {
		if (this.player) {
			this.player.play();
		}
		return this;
	},

	remove() {
		this.player = undefined;
		return Component.prototype.remove.apply(this, arguments);
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.createPlayer();
		return this;
	},

	sendChangePlaying(isPlaying) {
		this.isActive = isPlaying;
		this.send("changePlaying", this, isPlaying);
	}

});
