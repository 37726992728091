import Component from "../lib/components/component";
import Hammer from "hammerjs";
import Region from "../lib/components/region";
import assign from "lodash/assign";
import transition from "../lib/styles/transition";
import translate from "../lib/styles/translate";
import {
	onAnimationFrame,
	offAnimationFrame
} from "../lib/animation/animation-frame";

const getRegionOffset = (region, offset) => {
	const position = region.position || 0;
	offset = offset ? offset : 0;
	return offset - (position * 100);
};

const indexOfElement = (el) => {
	return Array.prototype.indexOf.call(el.parentNode.children, el);
};

const setRegionPosition = (region, position) => {
	const offset = getRegionOffset(region, position || 0);
	translate(region.el, offset + "%", 0);
};

const animateRegionPosition = (region, position) => {
	const offset = getRegionOffset(region, position || 0);
	return transition(region.el)
		.translateX(offset + "%")
		.duration(400)
		.ease("cubic-bezier(0.25, 0.1, 0.25, 1)")
		.start();
};

/**
@class SlideshowComponent
@extends Component
**/
export default Component.extend({

	// -- Public Properties ----------------------------------------------------

	props: {
		/**
		@property collection
		@type Collection
		@default undefined
		**/
		collection: {
			type: "collection"
		},

		/**
		@property index
		@type number
		@default 0
		**/
		index: {
			type: "number",
			required: true,
			default: 0
		},

		/**
		@property onChange
		@type Function
		@default undefined
		**/
		onChange: {
			type: "function"
		},

		/**
		@property visibleIndex
		@type number
		@default 0
		**/
		visibleIndex: {
			type: "number",
			required: true,
			default: 0
		}
	},

	derived: {
		/**
		@property total
		@type number
		@default 0
		**/
		total: {
			deps: ["collection"],
			fn() {
				return this.collection && this.collection.length || 0;
			}
		}
	},

	components: {
		/**
		@property current
		@type Region
		**/
		current: {
			type: Region,
			hook: "slideshow-first"
		},

		/**
		@property next
		@type Region
		**/
		next: {
			type: Region,
			hook: "slideshow-second"
		},

		/**
		@property prev
		@type Region
		**/
		prev: {
			type: Region,
			hook: "slideshow-third"
		}
	},

	// -- Lifecycle Methods ----------------------------------------------------

	constructor() {
		// Bind methods that will be called from hammer or animation frame to
		// this instance before `_handleElementChange` will be called for
		// initial `el`.
		this._onPanStart = this._onPanStart.bind(this);
		this._onPanMove = this._onPanMove.bind(this);
		this._onPanEnd = this._onPanEnd.bind(this);
		this._panMove = this._panMove.bind(this);
		this._panEnd = this._panEnd.bind(this);
		Component.apply(this, arguments);
	},

	initialize(options = {}) {
		Component.prototype.initialize.apply(this, arguments);
		if (options.component) {
			this.component = options.component;
		}
		this.visibleIndex = this.index;
		// Listen to index change
		this.on("change:collection", this.rerender);
		this.on("change:index", this._handleIndexChange);
		this.on("change:visibleIndex", this._handleVisibleIndexChange);
	},

	// -- Public Methods -------------------------------------------------------

	/**
	@method animate
	@param {number} direction The direction to be animated into. Either `1` for
		animation to next, `-1` for prev.
	@param {boolean} [reset]
	@param {Function} [callback]
	@chainable
	**/
	animate(direction, reset, callback) {
		if (typeof reset === "function") {
			callback = reset;
			reset = false;
		}

		if (!callback) {
			callback = () => {};
		}

		const that = this;
		const transitions = [];

		const newPrev = reset ? this.prev : this[direction > 0 ? "current" : "next"];
		const newCurrent = reset ? this.current : this[direction > 0 ? "next" : "prev"];
		const newNext = reset ? this.next : this[direction > 0 ? "prev" : "current"];

		// Animate current to prev only when:
		// 1. We want to change slides and direction is right to left [<-]
		// OR
		// 2. We want to reset to current slide, direction is left to right [->]
		// and we are not on first slide
		if ((!reset && direction > 0) || (reset && direction < 0 && !this.isFirst())) {
			transitions.push(animateRegionPosition(newPrev, -100));

		} else {
			setRegionPosition(newPrev, -100);
		}

		// Animate current to next only when:
		// 1. We want to change slides and direction is left to right [->]
		// OR
		// 2. We want to reset to current slide, direction is left to right [->]
		// and we are not on first slide
		if ((!reset && direction < 0) || (reset && direction > 0 && !this.isLast())) {
			transitions.push(animateRegionPosition(newNext, 100));

		} else {
			setRegionPosition(newNext, 100);
		}

		// newCurrent
		transitions.push(animateRegionPosition(newCurrent, 0));

		// Animate
		this._isAnimating = true;
		Promise.all(transitions).then(() => {
			that._isAnimating = false;
			if (!reset) {
				// Update region properties to reflect their position after the
				// move.
				that.prev = newPrev;
				that.next = newNext;
				that.current = newCurrent;
			}
			callback.call(that, direction, reset);
		});

		return this;
	},

	/**
	@method mountComponents
	@chainable
	**/
	mountComponents() {
		Component.prototype.mountComponents.apply(this, arguments);
		this.setupRegions();
		return this;
	},

	/**
	@method createComponentForModel
	@param {State} model
	@returns {Component}
	**/
	createComponentForModel(model) {
		let config;
		if (typeof this.component === "object") {
			config = assign({}, this.component);
		} else {
			config = {type: this.component || Component};
		}
		config.props = assign({model}, config.props);
		return this.createComponent(config);
	},

	/**
	@method delegateGestureEvents
	@chainable
	**/
	delegateGestureEvents() {
		this.gestureEventManager.on("panstart", this._onPanStart);
		this.gestureEventManager.on("panmove", this._onPanMove);
		this.gestureEventManager.on("panend", this._onPanEnd);
		this.gestureEventManager.on("pancancel", this._onPanEnd);
		return this;
	},

	/**
	@method isFirst
	@param {number} [direction]
	@returns {boolean}
	**/
	isFirst(direction) {
		return (this.visibleIndex + (direction || 0) <= 0);
	},

	/**
	@method isLast
	@param {number} [direction]
	@returns {boolean}
	**/
	isLast(direction) {
		return (this.visibleIndex + (direction || 0) >= this.total - 1);
	},

	/**
	@method isOutOfBounds
	@param {number} direction
	@returns {boolean}
	**/
	isOutOfBounds(direction) {
		return ((direction < 0 && this.isFirst()) || (direction > 0 && this.isLast()));
	},

	/**
	@method remove
	@chainable
	**/
	remove() {
		Component.prototype.remove.apply(this, arguments);
		this.gestureEventManager.destroy();
		return this;
	},

	/**
	@method renderRegions
	@chainable
	**/
	renderRegions() {
		this._updateRegionComponents(this.visibleIndex);
		return this;
	},

	/**
	@method render
	@chainable
	**/
	render() {
		this.mountComponents();
		return this;
	},

	/**
	@method rerender
	**/
	rerender() {
		if (this.rendered) {
			this._updateRegionComponents(this.index);
		}
		return this;
	},

	/**
	@method setupRegions
	@chainable
	**/
	setupRegions() {
		const currentModel = this.collection && this.collection.at(this.visibleIndex);
		// Store index of regions `el` within the parent
		this.current.position = indexOfElement(this.current.el);
		this.next.position = indexOfElement(this.next.el);
		this.prev.position = indexOfElement(this.prev.el);
		// Attach existing view in current
		this.current.mountComponent(this.createComponentForModel(currentModel));
		// Set transforms on region `el`
		setRegionPosition(this.current, 0);
		setRegionPosition(this.next, 100);
		setRegionPosition(this.prev, -100);
		// Render what is not already there
		this.renderRegions();
		return this;
	},

	/**
	@method show
	@param {number} newIndex
	@chainable
	**/
	show(newIndex) {
		const deltaIndex = newIndex - this.visibleIndex;
		const direction = deltaIndex > 0 ? 1 : -1;

		if (deltaIndex === 0 || newIndex < 0 || newIndex >= this.total) {
			// Do nothing if visibleIndex is already on newIndex or newIndex is
			// not a legal index of current collection
			return this;
		}

		if (!this.el) {
			this.visibleIndex = newIndex;
			return this;
		}

		if (deltaIndex < -1 || deltaIndex > 1) {
			// If we jump more than one position load the model into the
			// upcoming region
			this._setRegionComponentModel(this[direction > 0 ? "next" : "prev"], newIndex);
		}

		return this.animate(direction, () => {
			this.visibleIndex = newIndex;
		});
	},

	/**
	@method undelegateGestureEvents
	@chainable
	**/
	undelegateGestureEvents() {
		this.gestureEventManager.off("panstart");
		this.gestureEventManager.off("panmove");
		this.gestureEventManager.off("panend");
		this.gestureEventManager.off("pancancel");
		return this;
	},

	// -- Protected Methods ----------------------------------------------------

	/**
	@private
	@method _panMove
	**/
	_panMove() {
		const deltaX = this._pan.deltaX / this._pan.ratio;
		const direction = deltaX > 0 ? -1 : 1;
		const isOutOfBounds = this.isOutOfBounds(direction);

		setRegionPosition(this.current, isOutOfBounds ? deltaX / 2 : deltaX);

		if (!isOutOfBounds) {
			const upcomingRegion = this[direction > 0 ? "next" : "prev"];
			setRegionPosition(upcomingRegion, deltaX + 100 * direction);
		}
	},

	/**
	@private
	@method _panEnd
	**/
	_panEnd() {
		const deltaX = this._pan.deltaX;
		const direction = deltaX > 0 ? -1 : 1;
		const distance = deltaX * (direction * -1);
		const isOutOfBounds = this.isOutOfBounds(direction);
		const snapBack = (distance < 100 || isOutOfBounds);
		const newIndex = this.visibleIndex + (snapBack ? 0 : direction);

		this._pan = undefined;

		this.animate(direction, snapBack, () => {
			this.visibleIndex = newIndex;
		});
	},

	/**
	@private
	@method _setRegionComponentModel
	@param {Region} region
	@param {number} index
	**/
	_setRegionComponentModel(region, index) {
		const model = this.collection && this.collection.at(index) || null;
		if (region.component) {
			region.component.model = model;
		} else if (model) {
			region.show(this.createComponentForModel(model));
		} else {
			region.clear();
		}
	},

	/**
	@private
	@method _updateRegionComponents
	@param {number} index
	**/
	_updateRegionComponents(index) {
		this._setRegionComponentModel(this.current, index);
		this._setRegionComponentModel(this.next, index + 1);
		this._setRegionComponentModel(this.prev, index - 1);
	},

	// -- Protected Event Handlers ---------------------------------------------

	/**
	Overridden to support gesture events via `Hammer`.

	@private
	@method _handleElementChange
	@chainable
	**/
	_handleElementChange() {
		Component.prototype._handleElementChange.apply(this, arguments);
		if (this.gestureEventManager) {
			this.gestureEventManager.destroy();
		}
		this.gestureEventManager = new Hammer.Manager(this.el);
		this.gestureEventManager.add(new Hammer.Pan({
			direction: Hammer.DIRECTION_HORIZONTAL
		}));
		this.delegateGestureEvents();
		return this;
	},

	/**
	@private
	@method _handleIndexChange
	@param {Component} target
	@param {number} newVal
	**/
	_handleIndexChange(target, newVal) {
		this.show(newVal);
	},

	/**
	@private
	@method _handleVisibleIndexChange
	@param {Component} target
	@param {number} newVal
	**/
	_handleVisibleIndexChange(target, newVal) {
		this._updateRegionComponents(newVal);
		if (this.onChange) {
			this.onChange(newVal);
		}
	},

	/**
	@private
	@method _onPanStart
	@param {Event} e
	**/
	_onPanStart(e) {
		e.preventDefault();
		this._pan = {};
		this._pan.ratio = (this.el && this.el.offsetWidth || 0) / 100;
	},

	/**
	@private
	@method _onPanMove
	@param {Event} e
	**/
	_onPanMove(e) {
		e.preventDefault();
		this._pan.deltaX = e.deltaX;
		onAnimationFrame(this._panMove);
	},

	/**
	@private
	@method _onPanEnd
	@param {Event} e
	**/
	_onPanEnd(e) {
		e.preventDefault();
		this._pan.deltaX = e.deltaX;
		onAnimationFrame(this._panEnd);
		// Unregister eventually scheduled move
		offAnimationFrame(this._panMove);
	}

});
