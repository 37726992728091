import Component from "../lib/components/component";
import Playlist from "./playlist";
import YouTubePlayer from "./youtube-player";

export default Component.extend({

	props: {
		selected: "state"
	},

	components: {
		player: {
			type: YouTubePlayer,
			hook: "video-player-player",
			bindings: {
				onChangePlaying: "handleChangePlaying",
				videoId: "videoId"
			}
		},
		playlist: {
			type: Playlist,
			container: "[data-hook~=\"video-player-playlist\"]",
			bindings: {
				onSelect: "handleSelect"
			}
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.selected = this.playlist.at(0);
		this.on("change:selected", this._handleSelectedChange);
	},

	loadVideo(item) {
		this.setTitle(item.title);
		this.player.loadVideoById(item.videoId);
		if (this.player.isPlaying) {
			this.toggleItemPlaying(item.videoId, true);
		}
		return this;
	},

	pause() {
		if (this.player) {
			this.player.pause();
		}
		return this;
	},

	play() {
		if (this.player) {
			this.player.play();
		}
		return this;
	},

	setTitle(title) {
		const el = this.el && this.queryByHook("video-player-title");
		if (el) {
			el.textContent = title;
		}
		return this;
	},

	toggleItemPlaying(videoId, state) {
		const item = this.playlist.getItemByVideoId(videoId);
		if (item) {
			item.isPlaying = state;
		}
		return this;
	},

	handleChangePlaying(isPlaying) {
		this.toggleItemPlaying(this.player.videoId, isPlaying);
		this.send("changePlaying", this, isPlaying);
	},

	handleSelect(item) {
		if (this.selected && this.selected.videoId === item.videoId) {
			this.player.togglePlaying();

		} else {
			this.selected = item;
		}
	},

	_handleSelectedChange(target, newValue) {
		const prevValue = target.previous("selected");
		if (prevValue) {
			prevValue.isPlaying = false;
		}
		if (newValue) {
			this.loadVideo(newValue);
		}
	}

});
