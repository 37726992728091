import Component from "../lib/components/component";
import YouTubePlayer from "./youtube-player";

export default Component.extend({

	components: {
		player: {
			type: YouTubePlayer,
			selector: "[data-hook~=\"figure-media\"]",
			bindings: {
				onChangePlaying: "handleChangePlaying"
			}
		}
	},

	pause() {
		if (this.player) {
			this.player.pause();
		}
		return this;
	},

	play() {
		if (this.player) {
			this.player.play();
		}
		return this;
	},

	handleChangePlaying(isPlaying) {
		this.send("changePlaying", this, isPlaying);
	}

});
