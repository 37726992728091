import load from "load-script";

let loadingPromise;

const loadYouTubeIframeApi = () => {
	if (window.YT && window.YT.Player && window.YT.Player instanceof Function) {
		// If YouTube Iframe API is already loaded, return an immediateley
		// resolved Promise.
		return Promise.resolve(window.YT);
	}

	if (loadingPromise) {
		// If YouTube Iframe API is already loading but not finished yet,
		// just return the current loading promise again.
		return loadingPromise;
	}

	loadingPromise = new Promise((resolve) => {
		const previous = window.onYouTubeIframeAPIReady;

		window.onYouTubeIframeAPIReady = () => {
			if (previous) {
				previous();
			}
			window.onYouTubeIframeAPIReady = previous;
			loadingPromise = undefined;
			resolve(window.YT);
		};

		load("https://www.youtube.com/iframe_api");
	});

	return loadingPromise;
};

export default loadYouTubeIframeApi;
