var picturefill = require("picturefill");
var stringify = require("qs/lib/stringify");
var trim = require("lodash/trim");

function sortQueryParams(a, b) {
	return a.localeCompare(b);
}

function generateUrl(path, queryParams) {
	var query = stringify(queryParams, {
		arrayFormat: "brackets",
		encode: false,
		sort: sortQueryParams
	});
	return path + (query ? (path.indexOf("?") === -1 ? "?" : "&") + query : "");
}
exports.generateUrl = generateUrl;

function loadImage(src, callback) {
	callback || (callback = function () {});

	var img = new Image();
	var prop = img.naturalWidth ? "naturalWidth" : "width";

	img.src = src;

	if (img.complete) {
		callback(img[prop] ? img : null, img[prop] ? src : null);
	} else {
		img.onload = () => {
			callback(img, src);
		};
		img.onerror = () => {
			callback(null, null);
		};
	}

	return function abort() {
		callback = function () {};
	};
}
exports.loadImage = loadImage;

function parseSrcset(srcset) {
	var sets;
	if (!srcset) {
		return null;
	}
	sets = srcset.split(",");
	return sets.map(function (set) {
		var parts = trim(set).split(" ");
		return {
			url: parts[0],
			width: parseInt(parts[1], 10)
		};
	});
}
exports.parseSrcset = parseSrcset;

function defaultImageQueryFn() {
	return this.queryAll("img[srcset]");
}

function picturefillComponent(view, queryFn) {
	if (typeof picturefill === "function") {
		queryFn = queryFn || defaultImageQueryFn;
		var images = queryFn.call(view || this);
		if (images.length) {
			picturefill({
				elements: images
			});
		}
	}
}
exports.picturefillComponent = picturefillComponent;
