import State from "ampersand-state";
import Component from "../lib/components/component";
import template from "./caption.hbs";

export default Component.extend({

	props: {
		body: "string",
		credit: "string"
	},

	template,

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this._rendered = !!this.el;
		this.on("change:body", this.rerender);
		this.on("change:credit", this.rerender);
	},

	renderTemplate(context, template) {
		if (context instanceof State) {
			context = context.serialize({ derived: true });
		}
		return Component.prototype.renderTemplate.call(this, context, template);
	},

	rerender() {
		if (this.rendered) {
			this.render();
		}
		return this;
	}

});
