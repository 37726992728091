import MotionComponent from "./motion";
import translate from "../lib/styles/translate";

const setElTransform = (el, offset) => {
	translate(el, 0, offset + "px");
};

/**
@class LogoComponent
@extends MotionComponent
**/
export default MotionComponent.extend({

	props: {
		foregroundOffset: "number"
	},

	bindings: {
		foregroundOffset: {
			type: setElTransform,
			hook: "logo-foreground"
		}
	},

	didMove(rect, data) {
		const y = data.viewport.y > 0 ? data.viewport.y : 0;
		this.foregroundOffset = -1 * (y / 6);
	}

});
