import dom from "ampersand-dom";
import has3d from "../features/has-translate3d";
import prefix from "vendor-prefix";

let queuedAnimations = [];

const TRANSFORM = prefix.dash("transform");

const manipulateClassList = (el, [methodName, className, force]) => {
	if (methodName === "toggleClass") {
		force = force !== true && force !== false ? !dom.hasClass(className) : force;
		methodName = force ? "addClass" : "removeClass";
	}
	dom[methodName](el, className);
	return el;
};

export const executeQueuedElementAnimations = () => {
	for (let i = 0; i < queuedAnimations.length; i++) {
		queuedAnimations[i][0].draw(queuedAnimations[i][1]);
	}
	queuedAnimations = [];
};

export const createElementAnimation = () => {
	let classManipulations = [];
	let currentTransforms = {};
	let currentOpacity;

	const elementAnimation = {

		addClass(className) {
			classManipulations.push(["addClass", className]);
			return elementAnimation;
		},

		draw(el) {
			const transforms = [];
			for (let name in currentTransforms) {
				if (currentTransforms.hasOwnProperty(name)) {
					transforms.push(name + "(" + currentTransforms[name] + ")");
				}
			}

			if (transforms.length) {
				el.style.setProperty(TRANSFORM, transforms.join(" "), "");
			}

			if (currentOpacity !== undefined) {
				el.style.setProperty("opacity", currentOpacity, "");
				currentOpacity = undefined;
			}

			if (classManipulations.length) {
				for (let i = 0; i < classManipulations.length; i++) {
					manipulateClassList(el, classManipulations[i]);
				}
				classManipulations = [];
			}

			return elementAnimation;
		},

		opacity(value) {
			currentOpacity = value;
			return elementAnimation;
		},

		enqueue(el) {
			queuedAnimations.push([elementAnimation, el]);
			return elementAnimation;
		},

		removeClass(className) {
			classManipulations.push(["removeClass", className]);
			return elementAnimation;
		},

		rotate(value) {
			currentTransforms.rotate = value;
			return elementAnimation;
		},

		scale(value) {
			currentTransforms.scale = value;
			return elementAnimation;
		},

		skewX(value) {
			currentTransforms.skewX = value;
			return elementAnimation;
		},

		skewY(value) {
			currentTransforms.skewY = value;
			return elementAnimation;
		},

		toggleClass(className, force) {
			classManipulations.push(["toggleClass", className, force]);
			return elementAnimation;
		},

		translateX(value) {
			currentTransforms.translateX = value;
			return elementAnimation;
		},

		translateY(value) {
			currentTransforms.translateY = value;
			return elementAnimation;
		},

		translateZ(value) {
			if (has3d) {
				currentTransforms.translateZ = value;
			}
			return elementAnimation;
		}
	};

	return elementAnimation;
};
