import Component from "../lib/components/component";
import Components from "./components";

export default Component.extend({

	props: {
		filterUrl: "string",
		motionManager: "state",
		playing: "state"
	},

	components: {
		sections: {
			type: Components,
			hook: "page-components",
			bindings: {
				filterUrl: "filterUrl",
				motionManager: "motionManager"
			}
		}
	},

	actions: {

		changeFilter(url) {
			this.filterUrl = url;
		},

		changePlaying(track, isPlaying) {
			if (isPlaying) {
				if (this.playing && this.playing !== track) {
					this.playing.pause();
				}
				this.playing = track;
			} else if (this.playing === track) {
				this.unset("playing");
			}
		}
	}

});
