import State from "ampersand-state";
import createMotionService from "./motion";
import offsetRect from "../lib/dom/offset-rect";

const callHook = (target, name, ...args) => {
	if (target && target[name]) {
		target[name](...args);
	}
};

export const contains = (a, b) => {
	if (typeof a !== "object" || typeof b !== "object") {
		return false;
	}
	const aTop = a.y || 0;
	const aLeft = a.x || 0;
	const aRight = aLeft + (a.width || 0);
	const aBottom = aTop + (a.height || 0);
	const bTop = b.y || 0;
	const bLeft = b.x || 0;
	const bRight = bLeft + (b.width || 0);
	const bBottom = bTop + (b.height || 0);
	// A is considered to intersect with B when:
	// 1. B top is above A bottom
	// 2. B bottom is below A top
	// 3. B left is left of A right
	// 4. B right is right of A left
	return (bTop <= aBottom &&
			bBottom >= aTop &&
			bLeft <= aRight &&
			bRight >= aLeft);
};

export default State.extend({

	// -- Lifecycle Methods ----------------------------------------------------

	initialize() {
		this._rectByComponentId = {};
		this._observedComponents = [];
		this._visibleComponents = [];
		this.update = this.update.bind(this);
		this.motionService = createMotionService();
		this.motionService.subscribe(this.update);
	},

	// -- Public Methods -------------------------------------------------------

	getRectForComponent(component) {
		return component && component.cid && this._rectByComponentId[component.cid] || null;
	},

	getState() {
		return this.motionService.getState();
	},

	observeComponent(component) {
		if (this._observedComponents.indexOf(component) === -1) {
			this._observedComponents.push(component);
		}
		return component;
	},

	remove() {
		this.motionService.destroy();
		return this;
	},

	startMotionUpdates() {
		this.motionService.startMotionUpdates();
		return this;
	},

	startOrientationUpdates() {
		this.motionService.startOrientationUpdates();
		return this;
	},

	startViewportUpdates() {
		this.motionService.startViewportUpdates();
		return this;
	},

	stopMotionUpdates() {
		this.motionService.stopMotionUpdates();
		return this;
	},

	stopOrientationUpdates() {
		this.motionService.stopOrientationUpdates();
		return this;
	},

	stopViewportUpdates() {
		this.motionService.stopViewportUpdates();
		return this;
	},

	unobserveComponent(component) {
		const index = this._observedComponents.indexOf(component);
		if (index !== -1) {
			this._rectByComponentId[component.cid] = undefined;
			this._observedComponents.splice(index, 1);
		}
		return component;
	},

	update() {
		const state = this.getState();
		const viewport = state.viewport;
		const oldVisibleComponents = this._visibleComponents;
		const newVisibleComponents = [];
		const disappeared = [];
		const appeared = [];

		const components = this._observedComponents;
		for (let i = 0; i < components.length; i++) {
			const component = components[i];
			const rect = this._calculateRectForComponent(component);
			if (contains(rect, viewport)) {
				if (oldVisibleComponents.indexOf(component) === -1) {
					appeared.push(component);
				}
				newVisibleComponents.push(component);
				callHook(component, "willMove", rect, state);

			} else if (oldVisibleComponents.indexOf(component) !== -1) {
				disappeared.push(component);
			}
		}

		this._visibleComponents = newVisibleComponents;

		for (let i = 0; i < disappeared.length; i++) {
			callHook(disappeared[i], "didDisappear");
		}

		for (let i = 0; i < appeared.length; i++) {
			callHook(appeared[i], "didAppear");
		}

		for (let i = 0; i < newVisibleComponents.length; i++) {
			const component = newVisibleComponents[i];
			const rect = this.getRectForComponent(component);
			callHook(component, "didMove", rect, state);
		}

		return this;
	},

	_calculateRectForComponent(component) {
		if (component.el) {
			const rect = this._rectByComponentId[component.cid] = offsetRect(component.el);
			return rect;
		}
		return null;
	}

});
