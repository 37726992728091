var assign = require("lodash/assign");
var getValue = require("./get-value");

function set(obj, key, value) {
	if (obj && typeof key === "string" && value !== undefined) {
		obj[key] = value;
	}
	return obj;
}

module.exports = function mapStateToProps(context, bindings, defaults) {
	var result = defaults ? assign({}, defaults) : {};

	if (!bindings) {
		return result;

	} else if (typeof bindings === "function") {
		bindings(function (path) {
			return {
				to: function (prop) {
					set(result, prop, getValue(context, path));
				}
			};
		});

	} else {
		for (var key in bindings) {
			if (bindings.hasOwnProperty(key)) {
				set(result, key, getValue(context, bindings[key]));
			}
		}
	}

	return result;
};
