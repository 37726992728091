import assign from "lodash/assign";
import {
	UPDATE_MOTION,
	UPDATE_ORIENTATION,
	UPDATE_VIEWPORT,
	UPDATE_VIEWPORT_POSITION
} from "./actions";

export const motion = (state = {acceleration: {x: 0, y: 0, z: 0}, rotationRate: {alpha: 0, beta: 0, gamma: 0}}, action) => {
	switch (action.type) {
	case UPDATE_MOTION:
		return assign({}, state, action.data);
	default:
		return state;
	}
};

export const orientation = (state = {alpha: 0, beta: 0, gamma: 0}, action) => {
	switch (action.type) {
	case UPDATE_ORIENTATION:
		return assign({}, state, action.data);
	default:
		return state;
	}
};

export const viewport = (state = {x: 0, y: 0, height: 0, width: 0, orientation: 0}, action) => {
	switch (action.type) {
	case UPDATE_VIEWPORT:
	case UPDATE_VIEWPORT_POSITION:
		return assign({}, state, action.data);
	default:
		return state;
	}
};

export default (state = {}, action) => {
	return {
		motion: motion(state.motion, action),
		orientation: orientation(state.orientation, action),
		viewport: viewport(state.viewport, action)
	};
};
