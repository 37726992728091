import Component from "../components/component";
import Player from "./player";
import Poster from "./poster";

export default Component.extend({

	props: {
		/**
		@property autoplay
		@type boolean
		@default false
		**/
		autoplay: ["boolean", true, true],

		/**
		@property controls
		@type boolean
		@default true
		**/
		controls: ["boolean", true, true],

		/**
		@property onEndedChange
		@type Function
		**/
		onEndedChange: "function",

		/**
		@property onError
		@type Function
		**/
		onError: "function",

		/**
		@property onPlaybackQualityChange
		@type Function
		**/
		onPlaybackQualityChange: "function",

		/**
		@property onPlaybackRateChange
		@type Function
		**/
		onPlaybackRateChange: "function",

		/**
		@property onPlayingChange
		@type Function
		**/
		onPlayingChange: "function",

		/**
		@property onWaitingChange
		@type Function
		**/
		onWaitingChange: "function",

		/**
		@property playbackQuality
		@type number
		@default ""
		**/
		playbackQuality: ["string", true, ""],

		/**
		@property playbackRate
		@type number
		@default 1
		**/
		playbackRate: ["number", true, 1],

		/**
		@property rel
		@type boolean
		@default false
		**/
		rel: ["boolean", true, false],

		/**
		@property showinfo
		@type boolean
		@default false
		**/
		showinfo: ["boolean", true, false],

		/**
		@property videoId
		@type string
		@default ""
		**/
		videoId: ["string", true, ""],

		/**
		@property videoSrc
		@type string
		@default ""
		**/
		videoSrc: ["string", true, ""],

		/**
		@property volume
		@type number
		@default 1
		**/
		volume: ["number", true, 1]
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.parsePropsFromEl();
		this.initPoster();
	},

	handlePosterPlay() {
		this.initPlayer();
	},

	initPlayer() {
		this.player = this.createComponent({
			type: Player,
			bindings: {
				autoplay: "autoplay",
				controls: "controls",
				onEndedChange: "onEndedChange",
				onError: "onError",
				onPlaybackQualityChange: "onPlaybackQualityChange",
				onPlaybackRateChange: "onPlaybackRateChange",
				onPlayingChange: "onPlayingChange",
				onWaitingChange: "onWaitingChange",
				playbackQuality: "playbackQuality",
				playbackRate: "playbackRate",
				rel: "rel",
				showinfo: "showinfo",
				videoId: "videoId",
				videoSrc: "videoSrc",
				volume: "volume"
			}
		});
		this.player.render();
		this.removeComponent(this.poster);
		this.poster = undefined;
		this.el.appendChild(this.player.el);
		return this;
	},

	initPoster() {
		const posterEl = this.el && this.el.firstElementChild;
		this.poster = this.createComponent({
			type: Poster,
			el: posterEl,
			bindings: {
				onPlay: "handlePosterPlay"
			}
		});
		return this;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.parsePropsFromEl();
		const posterEl = this.el && this.el.firstElementChild;
		if (posterEl) {
			this.poster.mount(posterEl);
		}
		return this;
	},

	parsePropsFromEl() {
		if (this.el) {
			const props = {};
			if (this.el.hasAttribute("data-autoplay")) {
				props.autoplay = this.el.getAttribute("data-autoplay") !== "0";
			}
			if (this.el.hasAttribute("data-controls")) {
				props.controls = this.el.getAttribute("data-controls") !== "0";
			}
			if (this.el.hasAttribute("data-rel")) {
				props.rel = this.el.getAttribute("data-rel") !== "0";
			}
			if (this.el.hasAttribute("data-showinfo")) {
				props.showinfo = this.el.getAttribute("data-showinfo") !== "0";
			}
			if (this.el.hasAttribute("data-video-id")) {
				props.videoId = this.el.getAttribute("data-video-id");
			}
			this.set(props);
		}
		return this;
	},

	pause() {
		if (this.player) {
			this.player.pause();
		}
		return this;
	},

	play() {
		if (!this.player) {
			this.initPlayer();
		}
		this.player.play();
		return this;
	},

	remove() {
		this.player = undefined;
		this.poster = undefined;
		return Component.prototype.remove.apply(this, arguments);
	}

});
