var bind = require("lodash/bind");
var getPath = require("lodash/get");

module.exports = function getValue(context, path) {
	var value = getPath(context, path);
	if (typeof value === "function") {
		return bind(value, context);
	}
	return value;
};
