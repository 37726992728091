module.exports = {

	/**
	The collection of functions, keyed by name, available on this
	`ActionHandler` as action targets.

	These functions will be invoked from other parts of your application
	via `ActionHandler#send`.

	## Bubbling

	By default, an action will stop bubbling once a handler defined
	on the `actions` hash handles it. To continue bubbling the action,
	you must return `true` from the handler.

	@property actions
	@type Hash
	@default null
	**/

	/**
	Triggers a named action on the `ActionHandler`. Any parameters
	supplied after the `actionName` string will be passed as arguments
	to the action target function.

	If the `ActionHandler` has its `target` property set, actions may
	bubble to the `target`. Bubbling happens when an `actionName` can
	not be found in the `ActionHandler`'s `actions` hash or if the
	action target function returns `true`.

	Example

	```js
	var welcomeRoute = new Route({
		actions: {
			playTheme: function () {
				this.send('playMusic', 'theme.mp3');
			},
			playMusic: function (track) {
				// ...
			}
		}
	});
	```

	@method send
	@param {String} actionName The action to trigger
	@param {*} context a context to send with the action
	**/
	send: function (actionName) {
		var args = Array.prototype.slice.call(arguments, 1);
		var parent = this.parent;

		if (this.actions && this.actions[actionName]) {
			if (this.actions[actionName].apply(this, args) !== true) {
				return;
			}
			// handler returned true, so this action will bubble
		}

		if (parent) {
			if (typeof parent.send !== "function") {
				throw new Error("The `parent` does not have a `send` method.");
			}
			parent.send.apply(parent, arguments);
		}
	}

};
