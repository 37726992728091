import MotionComponent, {travel} from "./motion";
import {createParallaxSet} from "../lib/parallax";

export default MotionComponent.extend({

	initialize() {
		MotionComponent.prototype.initialize.apply(this, arguments);
		this.initParallaxes();
	},

	didMove(rect, data) {
		if (this.parallaxes) {
			this.parallaxes.draw(travel(rect, data.viewport), rect.width / 100 * 3);
		}
	},

	initParallaxes() {
		if (this.el) {
			const elements = this.queryAll("[data-parallax]");
			this.parallaxes = createParallaxSet(elements);
		}
		return this;
	},

	mount() {
		MotionComponent.prototype.mount.apply(this, arguments);
		this.initParallaxes();
		return this;
	},

	remove() {
		this.parallaxes = undefined;
		return MotionComponent.prototype.remove.apply(this, arguments);
	},

	render() {
		MotionComponent.prototype.render.apply(this, arguments);
		this.initParallaxes();
		return this;
	}

});
