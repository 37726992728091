import Component from "../lib/components/component";
import PosterVideo from "./poster-video";

export default Component.extend({

	props: {
		motionManager: "state"
	},

	components: {
		poster: {
			type: PosterVideo,
			container: "[data-hook~=\"section-visual\"]",
			bindings: {
				motionManager: "motionManager"
			}
		}
	}

});
