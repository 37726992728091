import {scrollTop} from "./utils";

export const UPDATE_MOTION = "UPDATE_MOTION";

export const updateMotion = ({acceleration: {x = 0, y = 0, z = 0}, rotationRate: {alpha = 0, beta = 0, gamma = 0}}) => {
	return {
		type: UPDATE_MOTION,
		data: {
			acceleration: {
				x: Math.round(x),
				y: Math.round(y),
				z: Math.round(z)
			},
			rotationRate: {
				alpha: Math.round(alpha),
				beta: Math.round(beta),
				gamma: Math.round(gamma)
			}
		}
	};
};

export const UPDATE_ORIENTATION = "UPDATE_ORIENTATION";

export const updateOrientation = ({alpha = 0, beta = 0, gamma = 0}) => {
	return {
		type: UPDATE_ORIENTATION,
		data: {
			alpha: Math.round(alpha),
			beta: Math.round(beta),
			gamma: Math.round(gamma)
		}
	};
};

export const UPDATE_VIEWPORT = "UPDATE_VIEWPORT";

export const updateViewport = () => {
	return {
		type: UPDATE_VIEWPORT,
		data: {
			x: 0,
			y: scrollTop(),
			height: window.innerHeight,
			width: window.innerWidth,
			orientation: window.orientation || 0
		}
	};
};

export const UPDATE_VIEWPORT_POSITION = "UPDATE_VIEWPORT_POSITION";

export const updateViewportPosition = () => {
	return {
		type: UPDATE_VIEWPORT_POSITION,
		data: {
			x: 0,
			y: scrollTop()
		}
	};
};
