import Collection from "ampersand-collection";
import State from "ampersand-state";

export const Slide = State.extend({

	props: {
		id: "string",
		caption: "string",
		credit: "string",
		hotspot: "string",
		ratio: "number",
		srcset: "string"
	}

});

export const SlideCollection = Collection.extend({

	model: Slide

});
