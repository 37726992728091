import trim from "lodash/trim";

const devicePixelRatio = window && window.devicePixelRatio || 1;

export const isDefined = (value) => (value !== undefined && value !== null);

export const findSrcForWidth = (assets, width) => {
	if (!assets) {
		return null;
	}
	const pixelWidth = width * devicePixelRatio;
	let url;
	for (let i = 0, l = assets.length; i < l; i++) {
		const asset = assets[i];
		if (asset.width >= pixelWidth) {
			return asset.url;
		}
		// Store for fallback
		url = asset.url;
	}
	// Return with last url from the srcset
	return url;
};

export const loadImage = (src, listener = () => {}) => {
	const img = new Image();
	const prop = img.naturalWidth ? "naturalWidth" : "width";

	let isSubscribed = true;
	img.src = src;

	if (img.complete) {
		listener(img[prop] ? img : null, img[prop] ? src : null);

	} else {
		img.onload = () => {
			if (isSubscribed) {
				listener(img, src);
			}
		};
		img.onerror = () => {
			if (isSubscribed) {
				listener(null, null);
			}
		};
	}

	const unsubscribe = () => {
		isSubscribed = false;
	};

	return unsubscribe;
};

export const normalizeWidth = (viewportWidth, viewportHeight, imageRatio) => {
	if (viewportHeight && imageRatio) {
		const viewportRatio = viewportHeight / viewportWidth;
		if (imageRatio < viewportRatio) {
			return viewportHeight / imageRatio;
		}
	}
	// Default to the given width
	return viewportWidth;
};

export const parseSrcset = (srcset) => {
	if (srcset) {
		const sets = srcset.split(",");
		return sets.map((set) => {
			const [url, width] = trim(set).split(" ");
			return {
				url,
				width: parseInt(width, 10)
			};
		});
	}
	return [];
};

export const createResponsiveBackground = (element) => {
	if (!(element instanceof HTMLElement)) {
		throw new Error("Expected an HTMLElement for 'createResponsiveBackground'.");
	}

	let assets;
	let currentRatio;
	let currentSrc;
	let currentWidth;
	let abortLoading;

	// API methods

	const el = () => {
		return element;
	};

	const ratio = (value) => {
		if (typeof value === "string") {
			value = parseFloat(value);
		}
		if (value !== value) { // equiv. of Number.isNaN(value), but better support
			value = undefined;
		}
		currentRatio = value;
	};

	const srcset = (value) => {
		assets = parseSrcset(value);
	};

	const setSrc = (src) => {
		element.style.backgroundImage = src ? "url(" + src + ")" : "";
		currentSrc = src;
	};

	const update = (width, height) => {
		const imageWidth = normalizeWidth(width, height, currentRatio);
		if (!currentWidth || imageWidth > currentWidth) {
			const src = findSrcForWidth(assets, imageWidth);
			if (src !== currentSrc) {
				if (abortLoading) {
					abortLoading();
				}
				abortLoading = loadImage(src, (img) => {
					abortLoading = null;
					setSrc(img ? src : "");
					currentWidth = imageWidth;
				});
			}
		}
	};

	const resize = (width, height) => {
		if (!isDefined(width)) {
			width = element.offsetWidth;
		}
		if (!isDefined(height) && currentRatio) {
			height = element.offsetHeight;
		}
		update(width, height);
	};

	const parse = () => {
		ratio(element.getAttribute("data-ratio"));
		srcset(element.getAttribute("data-srcset"));
	};

	// Init
	parse();

	// Expose API
	return {
		el,
		parse,
		ratio,
		resize,
		srcset,
		update
	};
};

export const createResponsiveBackgroundSet = (elements) => {
	if (!Array.isArray(elements)) {
		throw new Error("Expected an array of HTMLElements for 'createResponsiveBackgroundSet'.");
	}

	let items;

	const find = (el) => {
		for (let i = 0; i < items.length; i++) {
			if (items[i].el() === el) {
				return items[i];
			}
		}
		return null;
	};

	const forEach = (iterator = () => {}) => {
		for (let i = 0; i < items.length; i++) {
			iterator(items[i], i, items);
		}
	};

	const parse = () => {
		for (let i = 0; i < items.length; i++) {
			items[i].parse();
		}
	};

	const update = (width, height) => {
		for (let i = 0; i < items.length; i++) {
			items[i].update(width, height);
		}
	};

	const resize = (width, height) => {
		for (let i = 0; i < items.length; i++) {
			items[i].resize(width, height);
		}
	};

	// Init
	items = elements.map(createResponsiveBackground);

	return {
		find,
		forEach,
		parse,
		resize,
		update
	};
};
