var State = require("ampersand-state");
var assign = require("lodash/assign");
var uniqueId = require("lodash/uniqueId");

var BaseState = State.extend({
	dataTypes: {
		element: {
			set: function (newVal) {
				return {
					val: newVal,
					type: newVal instanceof Element ? "element" : typeof newVal
				};
			},
			compare: function (elementA, elementB) {
				return elementA === elementB;
			}
		}
	},
	props: {
		component: "object",
		el: "element"
	},
	session: {
		_animating: {
			type: "boolean",
			required: true,
			default: false
		},
		_rendered: {
			type: "boolean",
			required: true,
			default: false
		}
	},
	derived: {
		animating: {
			deps: ["_animating"],
			fn: function () {
				return this._animating;
			}
		},
		rendered: {
			deps: ["_rendered"],
			fn: function () {
				if (this._rendered) {
					this.trigger("render", this);
					return true;
				}
				this.trigger("remove", this);
				return false;
			}
		}
	}
});

/**
@class Region
@extends State
**/
function Region(attrs) {
	attrs = attrs || {};
	this.cid = uniqueId("region");
	var parent = attrs.parent;
	delete attrs.parent;
	if (typeof attrs.animate === "function") {
		this.animate = attrs.animate;
		delete attrs.animate;
	}
	BaseState.call(this, attrs, {init: false, parent: parent});
	this.on("change:component", this._handleComponentChange, this);
	this.on("change:el", this._handleElementChange, this);
	this._cache.animating = false; // prep `animating` derived cache immediately
	this._cache.rendered = false; // prep `rendered` derived cache immediately
	this.initialize.apply(this, arguments);
}

/**
@static
@method extend
@param {Object} props One or more hashes of inheritable prototype members for
	the resulting class.
@return {Function} Factory function for the new class.
**/
Region.extend = BaseState.extend;

Region.prototype = Object.create(BaseState.prototype);

assign(Region.prototype, {

	// -- Public Methods -------------------------------------------------------

	/**
	@method clear
	@chainable
	**/
	clear: function (options) {
		this.set("component", null, options);
		return this;
	},

	/**
	@method mountComponent
	@chainable
	**/
	mountComponent: function (component) {
		var componentEl = this.el && (this.el.firstElementChild || this.el.children[0]);
		var options = {};
		if (componentEl) {
			if (typeof component.mount === "function") {
				component.mount(componentEl);
			} else {
				component.el = componentEl;
			}
			options.update = false;
		}
		this.show(component, options);
		return this;
	},

	/**
	@method mount
	@param {Element} element The element that will be set to `this.el`.
	@chainable
	**/
	mount: function (el) {
		if (!el) {
			throw new Error("Missing 'el' for mounting Region.");
		}

		if (this._rendered) {
			throw new Error("Only non rendered Regions can be mounted.");
		}

		this.el = el;

		if (this.component) {
			this.mountComponent(this.component);
		}

		this._rendered = true;
		return this;
	},

	/**
	@method insertComponent
	@chainable
	**/
	insertComponent: function (component, prepend) {
		if (prepend) {
			this.el.insertBefore(component.el, this.el.firstChild);
		} else {
			this.el.appendChild(component.el);
		}
		this.trigger("show", this, component);
		return this;
	},

	/**
	@method removeComponent
	@chainable
	**/
	removeComponent: function (component) {
		this.trigger("hide", this, component);
		component.remove();
		return this;
	},

	/**
	@method show
	@chainable
	**/
	show: function (component, options) {
		this.set("component", component, options);
		return this;
	},

	// -- Protected Methods ----------------------------------------------------

	/**
	@private
	@method _animate
	@chainable
	**/
	_animate: function (newComponent, prevComponent, callback) {
		this._animating = true;
		if (prevComponent) {
			this.removeComponent(prevComponent);
		}
		if (newComponent) {
			this.insertComponent(newComponent);
		}
		this._animating = false;
		if (callback) {
			callback.call(this, newComponent);
		}
		return this;
	},

	/**
	Removes this region by taking the element out of the DOM, and removing any
	contained components.

	@private
	@method _remove
	@chainable
	**/
	_remove: function () {
		this.clear();
		if (this.el && this.el.parentNode) {
			this.el.parentNode.removeChild(this.el);
		}
		this._rendered = false;
		return this;
	},

	/**
	@private
	@method _render
	@chainable
	**/
	_render: function () {
		if (!this.el) {
			throw new Error("Region requires an `el` to render.");
		}
		if (this.component) {
			this.component.render();
		}
		return this;
	},

	// -- Private Event Handlers -----------------------------------------------

	/**
	@private
	@method _handleComponentChange
	@chainable
	**/
	_handleComponentChange: function (target, newComponent, options) {
		options = options || {};
		var prevComponent = this.previous("component");
		var callback = options.callback;
		var update = (options.update !== false);
		// When a value is specified for `options.render`, prefer it because
		// it represents the developer's intent. When no value is specified,
		// the `component` will be rendered.
		var render = update && (options.render !== false);

		if (prevComponent) {
			prevComponent.off("remove", this._handleComponentRemove, this);
			if (!update) {
				// If DOM will not be updated call `hide` event for previous
				// component immediately. Otherwise it will be called inside
				// `removeComponent`.
				this.trigger("hide", this, prevComponent);
			}
		}

		if (newComponent) {
			newComponent.once("remove", this._handleComponentRemove, this);
			if (render) {
				newComponent.render();
			}
			if (!update) {
				// If DOM will not be updated call `show` event for new
				// component immediately. Otherwise it will be called inside
				// `insertComponent`.
				this.trigger("show", this, newComponent);
			}
		}

		if (update) {
			this.animate(newComponent, prevComponent, callback);
		} else if (callback) {
			callback.call(this, newComponent);
		}
		return this;
	},

	/**
	@private
	@method _handleComponentRemove
	**/
	_handleComponentRemove: function (component) {
		if (this.component === component) {
			this.set("component", null, {update: false});
		}
	},

	/**
	@private
	@method _handleElementChange
	@chainable
	**/
	_handleElementChange: function () {
		return this;
	}

});

Object.defineProperty(Region.prototype, "animate", {
	get: function () {
		return this._animate;
	},
	set: function (fn) {
		this._animate = function (newComponent, prevComponent, cb) {
			var self = this;
			self._animating = true;
			fn.call(self, newComponent, prevComponent, function () {
				self._animating = false;
				if (cb) {
					cb.apply(self, arguments);
				}
			});
			return self;
		};
	}
});

Object.defineProperty(Region.prototype, "remove", {
	get: function () {
		return this._remove;
	},
	set: function (fn) {
		this._remove = function() {
			fn.apply(this, arguments);
			this._rendered = false;
			return this;
		};
	}
});

Object.defineProperty(Region.prototype, "render", {
	get: function () {
		return this._render;
	},
	set: function (fn) {
		this._render = function() {
			fn.apply(this, arguments);
			this._rendered = true;
			return this;
		};
	}
});

module.exports = Region;
