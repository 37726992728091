import MotionComponent from "./motion";
import domify from "domify";
import {picturefillComponent} from "../lib/utils";
import {scrollTop} from "../services/motion/utils";

const parseItems = (el) => {
	const fragment = document.createDocumentFragment();
	const list = el.querySelector("[data-hook~=\"list-items\"]");
	const children = list && Array.prototype.slice.call(list.children, 0) || [];
	for (let i = 0; i < children.length; i++) {
		fragment.appendChild(children[i]);
	}
	return fragment;
};

const parseMeta = (el) => {
	return {
		nextPageUrl: el.getAttribute("data-next-url"),
		prevPageUrl: el.getAttribute("data-prev-url")
	};
};

const parsePage = (body) => {
	const dom = typeof body === "string" ? domify(body) : body;
	const el = dom.querySelector("[data-component=\"paginated-list\"]");
	return {
		items: parseItems(el),
		meta: parseMeta(el)
	};
};

const preserveScrollYAfterInsert = (container, insert) => {
	const prevHeight = container.offsetHeight;
	insert();
	const newHeight = container.offsetHeight;
	const deltaHeight = newHeight - prevHeight;
	const scrollY = scrollTop();
	window.scroll(0, scrollY + deltaHeight);
};

export default MotionComponent.extend({

	props: {
		filterUrl: "string",
		isLoading: "boolean",
		nextPageUrl: "string",
		prevPageUrl: "string"
	},

	derived: {
		bottomIsLoaded: {
			deps: ["nextPageUrl"],
			fn() {
				return !this.nextPageUrl;
			}
		},
		topIsLoaded: {
			deps: ["prevPageUrl"],
			fn() {
				return !this.prevPageUrl;
			}
		}
	},

	bindings: {
		bottomIsLoaded: {
			type: "booleanClass"
		},
		isLoading: {
			type: "booleanClass"
		},
		topIsLoaded: {
			type: "booleanClass"
		}
	},

	events: {
		"click [data-hook~=\"paginated-list-button-next\"]": "_onNextClick",
		"click [data-hook~=\"paginated-list-button-prev\"]": "_onPrevClick"
	},

	actions: {

		loading(promise) {
			this.isLoading = true;
			promise.then(res => {
				this.isLoading = false;
				return res;
			}, err => {
				this.isLoading = false;
				throw err;
			});
			// Let the loading action bubble up
			return true;
		},

		nextPage() {
			if (!this.bottomIsLoaded) {
				this.load(this.nextPageUrl);
			}
		},

		prevPage() {
			if (!this.topIsLoaded) {
				this.load(this.prevPageUrl, {prev: true});
			}
		}

	},

	initialize() {
		MotionComponent.prototype.initialize.apply(this, arguments);
		this.on("change:filterUrl", this._handleFilterUrlChange);
		this.parseProps();
	},

	addItems(items, {prepend, reset} = {}) {
		const list = this.queryByHook("list-items");
		if (list) {
			if (reset) {
				while (list.firstChild) {
					list.removeChild(list.firstChild);
				}
			}
			if (prepend) {
				list.insertBefore(items, list.children[0]);
			} else {
				list.appendChild(items);
			}
			picturefillComponent(this);
		}
		return this;
	},

	didMove(rect, {viewport}) {
		const threshold = viewport.height;
		const fromTopFold = viewport.y - rect.y;
		const fromBottomFold = (viewport.y + viewport.height) - (rect.y + rect.height);

		if (fromTopFold < threshold) {
			this.nearTop();
		}

		if (fromBottomFold > threshold * -1) {
			this.nearBottom();
		}
	},

	load(pageUrl, {prev, reset} = {}) {
		const promise = fetch(pageUrl, {credentials: "same-origin"})
			.then(response => response.text())
			.then(body => parsePage(body))
			.then(({items, meta}) => {

				const insert = () => {
					if (reset) {
						this.set(meta);
						this.addItems(items, {reset: true});
					} else {
						const prop = prev ? "prevPageUrl" : "nextPageUrl";
						this.set(prop, meta[prop]);
						this.addItems(items, {prepend: !!prev});
					}
				};

				if (prev) {
					preserveScrollYAfterInsert(this.el, insert);
				} else {
					insert();
				}

				this.send("trackPageview", {page: pageUrl});
			}).then(null, err => {
				this.send("error", err);
			});

		this.send("loading", promise);
		return promise;
	},

	nearBottom() {
		if (this.bottomIsLoaded || this.isLoading) {
			return;
		}
		this.send("nextPage");
	},

	nearTop() {
		if (this.topIsLoaded || this.isLoading) {
			return;
		}
		this.send("prevPage");
	},

	parseProps() {
		if (this.el) {
			this.set(parseMeta(this.el));
		}
		return this;
	},

	_handleFilterUrlChange(target, newFilterUrl) {
		this.load(newFilterUrl, {reset: true});
	},

	_onNextClick(e) {
		this.send("nextPage");
		e.preventDefault();
	},

	_onPrevClick(e) {
		this.send("prevPage");
		e.preventDefault();
	}

});
