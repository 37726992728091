import Component from "../lib/components/component";
import closest from "../lib/dom/closest";
import dom from "ampersand-dom";

export default Component.extend({

	props: {
		expandedElement: "element"
	},

	events: {
		"click a": "_onButtonClick",
		"click button": "_onButtonClick"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this._onOutsideClick = this._onOutsideClick.bind(this);
		this.listenTo(this, "change:expandedElement", this._handleExpandedElementChange);
	},

	remove() {
		this.unset("expandedElement");
		return Component.prototype.remove.apply(this, arguments);
	},

	toggleElementExpandedState(el, state) {
		if (el) {
			dom[state ? "addClass" : "removeClass"](el, "isExpanded");
		}
		return this;
	},

	toggleExpanded(el, state) {
		state = state === undefined ? this.expandedElement !== el : state;
		if (state) {
			this.expandedElement = el;
		} else {
			this.unset("expandedElement");
		}
		return this;
	},

	_handleExpandedElementChange(target, newVal) {
		const prevVal = this.previous("expandedElement");
		if (prevVal) {
			if (!newVal) {
				document.removeEventListener("click", this._onOutsideClick, false);
			}
			this.toggleElementExpandedState(prevVal, false);
		}
		if (newVal) {
			this.toggleElementExpandedState(newVal, true);
			if (!prevVal) {
				document.addEventListener("click", this._onOutsideClick, false);
			}
		}
	},

	_onButtonClick(e) {
		const target = e.delegateTarget || e.currentTarget;
		const hook = target && target.getAttribute("data-hook");
		if (hook && hook.indexOf("section-nav-dropdown-toggle") !== -1) {
			const expandableEl = closest(target, "[data-hook~=\"section-nav-dropdown\"]");
			if (expandableEl) {
				this.toggleExpanded(expandableEl);
			}
			e.preventDefault();
			return;
		}

		const local = (target.host === window.location.host);
		const hasTarget = target.target && target.target === "_blank";
		// if it's a plain click (no modifier keys)
		// and it's a local url, navigate internally
		if (local && !hasTarget && !e.ctrlKey && !e.shiftKey && !e.altKey &&
				!e.metaKey && !e.defaultPrevented) {
			e.preventDefault();
			this.unset("expandedElement");
			this.send("navigateIfHasRoute", target.pathname + target.search);
		}
	},

	_onOutsideClick(e) {
		if (!this.expandedElement.contains(e.target)) {
			this.unset("expandedElement");
		}
	}

});
