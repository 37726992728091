import Component from "../lib/components/component";
import Logo from "./logo";

export default Component.extend({

	props: {
		motionManager: "state"
	},

	components: {
		logo: {
			type: Logo,
			container: "[data-hook~=\"masthead-logo\"]",
			bindings: {
				motionManager: "motionManager"
			}
		}
	},

	events: {
		"click [data-hook~=\"masthead-menu-button\"]": "_onMenuButtonClick"
	},

	_onMenuButtonClick(e) {
		e.preventDefault();
		this.send("toggleMenu");
	}

});
