// IMPORTANT: This file has to be a commonJS module for interoperability reasons.
var assign = require("lodash/assign");

module.exports = assign({

	/**
	Current Locale

	@property locale
	**/
	locale: "de"

	/**
	Route configuration

	@property routes
	**/

}, window && window.__CONFIG__ || {});
