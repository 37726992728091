import Media from "./media";
import assign from "lodash/assign";

/**
Manages an `HTMLVideoElement` in the DOM.

Overview of video element API and events:
http://www.w3.org/2010/05/video/mediaevents.html

@class Video
@extends Media
**/
export default Media.extend({

	// -- Public Properties ----------------------------------------------------

	props: {

		/**
		@property videoHeight
		@type number
		@default 0
		**/
		videoHeight: ["number", true, 0],

		/**
		@property videoWidth
		@type number
		@default 0
		**/
		videoWidth: ["number", true, 0]
	},

	events: assign({
		"loadedmetadata": "_onLoadedmetadata",
		"resize": "_onResize"
	}, Media.prototype.events),

	render() {
		this.el = document.createElement("video");
		this.el.src = this.src;
		if (this.playbackRate !== undefined) {
			this.el.playbackRate = this.playbackRate;
		}
		if (this.volume !== undefined) {
			this.el.volume = this.volume;
		}
		return this;
	},

	/**
	The user agent has just determined the duration and dimensions of the media
	resource and the text tracks are ready.

	@private
	@method _onLoadedmetadata
	@param {Event} e
	**/
	_onLoadedmetadata() {
		this.set({
			duration: this.el.duration,
			videoHeight: this.el.videoHeight,
			videoWidth: this.el.videoWidth
		}, {src: "UI"});
	},

	/**
	One or both of the videoWidth and videoHeight attributes have just been
	updated.

	@private
	@method _onResize
	@param {Event} e
	**/
	_onResize() {
		this.set({
			videoHeight: this.el.videoHeight,
			videoWidth: this.el.videoWidth
		}, {src: "UI"});
	}

});
