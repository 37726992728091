import reducer from "./reducers";
import {bind, unbind} from "component-event";
import {onAnimationFrame} from "../../lib/animation/animation-frame";
import {updateMotion, updateOrientation, updateViewport, updateViewportPosition} from "./actions";

export default function createMotionService() {
	let currentState;
	let isMotionActive = false;
	let isOrientationActive = false;
	let isViewportActive = false;
	let listeners = [];
	let resizeTimer;

	const getState = () => {
		return currentState;
	};

	const notify = () => {
		for (let i = 0; i < listeners.length; i++) {
			const listener = listeners[i];
			listener();
		}
	};

	const dispatch = (action) => {
		if (typeof action !== "object") {
			throw new Error("Expected action to be an object.");
		}

		if (typeof action.type === "undefined") {
			throw new Error("Actions may not have an undefined 'type' property.");
		}

		currentState = reducer(currentState, action);
		onAnimationFrame(notify);
	};

	// -- Event Handlers -------------------------------------------------------

	const afterResize = () => {
		dispatch(updateViewport());
	};

	const onDeviceMotion = (e) => {
		dispatch(updateMotion(e));
	};

	const onDeviceOrientation = (e) => {
		dispatch(updateOrientation(e));
	};

	const onOrientationChange = () => {
		dispatch(updateViewport());
	};

	const onResize = () => {
		if (resizeTimer) {
			clearTimeout(resizeTimer);
		}
		resizeTimer = setTimeout(afterResize, 100);
	};

	const onScroll = () => {
		dispatch(updateViewportPosition());
	};

	// -- Public Methods -------------------------------------------------------

	const isMotionAvailable = () => !!window.DeviceMotionEvent;

	const isOrientationAvailable = () => !!window.DeviceOrientationEvent;

	const startMotionUpdates = () => {
		if (isMotionAvailable() && !isMotionActive) {
			bind(window, "devicemotion", onDeviceMotion);
			isMotionActive = true;
		}
	};

	const startOrientationUpdates = () => {
		if (isOrientationAvailable() && !isOrientationActive) {
			bind(window, "deviceorientation", onDeviceOrientation);
			isOrientationActive = true;
		}
	};

	const startViewportUpdates = () => {
		if (!isViewportActive) {
			bind(window, "scroll", onScroll);
			bind(window, "resize", onResize);
			bind(window, "orientationchange", onOrientationChange);
			isViewportActive = true;
			dispatch(updateViewport());
		}
	};

	const stopMotionUpdates = () => {
		if (isMotionActive) {
			unbind(window, "devicemotion", onDeviceMotion);
			isMotionActive = false;
		}
	};

	const stopOrientationUpdates = () => {
		if (isOrientationActive) {
			unbind(window, "deviceorientation", onDeviceOrientation);
			isOrientationActive = false;
		}
	};

	const stopViewportUpdates = () => {
		if (isViewportActive) {
			unbind(window, "scroll", onScroll);
			unbind(window, "resize", onResize);
			unbind(window, "orientationchange", onOrientationChange);
			isViewportActive = false;
		}
	};

	const destroy = () => {
		stopMotionUpdates();
		stopOrientationUpdates();
		stopViewportUpdates();
		listeners = [];
	};

	const subscribe = (listener) => {
		if (typeof listener !== "function") {
			throw new Error("Expected listener to be a function.");
		}

		let isSubscribed = true;
		listeners.push(listener);

		const unsubscribe = () => {
			if (!isSubscribed) {
				return;
			}
			isSubscribed = false;
			const index = listeners.indexOf(listener);
			listeners.splice(index, 1);
		};

		return unsubscribe;
	};

	return {
		destroy,
		dispatch,
		getState,
		isMotionAvailable,
		isOrientationAvailable,
		startMotionUpdates,
		startOrientationUpdates,
		startViewportUpdates,
		stopMotionUpdates,
		stopOrientationUpdates,
		stopViewportUpdates,
		subscribe
	};
}
