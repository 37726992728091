var offset = require("document-offset");

module.exports = function (el) {
	if (!el || !(el instanceof Node)) {
		return null;
	}
	var elOffset = offset(el);
	return {
		x: elOffset.left,
		y: elOffset.top,
		height: el.offsetHeight,
		width: el.offsetWidth
	};
};
