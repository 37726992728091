import Component from "../lib/components/component";
import Track from "./track";
import assign from "lodash/assign";

export default Component.extend({

	component: {
		type: Track
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.createTrackComponents();
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.createTrackComponents();
		return this;
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.createTrackComponents();
		return this;
	},

	createTrackComponents() {
		const items = this.el ? this.queryAllByHook("track-list-item") : null;
		if (items && items.length) {
			items.forEach(item => {
				const el = item.children.length > 0 ? item.children[0] : null;
				if (el) {
					this.createComponent(assign({el}, this.component));
				}
			});
		}
		return this;
	}

});
