import Component from "../lib/components/component";
import {SlideCollection} from "../models/slide";

export default Component.extend({

	props: {
		motionManager: "state"
	},

	collections: {
		slides: SlideCollection
	},

	events: {
		"click [data-hook~=\"gallery-preview-item\"]": "_onItemClick"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.parseSlides();
	},

	parseSlides() {
		const container = this.el && this.query("script[type=\"application/json\"]");
		if (container) {
			try {
				const payload = JSON.parse(container.textContent);
				this.slides.reset(payload);
			} catch (e) {
				this.send("error", e);
			}
		}
		return this;
	},

	sendOpenGallery(id) {
		const slide = this.slides.get(id);
		this.send("openGallery", this.slides, this.slides.indexOf(slide));
	},

	_onItemClick(e) {
		e.preventDefault();
		const target = e.delegateTarget || e.currentTarget;
		if (target && target.id) {
			this.sendOpenGallery(target.id);
		}
	}

});
