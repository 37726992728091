import Component from "../lib/components/component";
import dom from "ampersand-dom";

export default Component.extend({

	props: {
		activeSection: "string"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.on("change:activeSection", this._handleActiveSectionChange);
	},

	toggleSectionState(name, state) {
		const sectionEl = this.query("[data-section~=\"" + name + "\"]");
		if (sectionEl) {
			state = state === undefined ? !dom.hasClass("isActive") : state;
			dom[state ? "addClass" : "removeClass"](sectionEl, "isActive");
		}
		return this;
	},

	_handleActiveSectionChange(target, newVal) {
		const prevVal = target.previous("activeSection");
		if (prevVal) {
			this.toggleSectionState(prevVal, false);
		}
		if (newVal) {
			this.toggleSectionState(newVal, true);
		}
	}

});
