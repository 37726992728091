import Component from "../lib/components/component";
import Track from "./track";

export default Component.extend({

	components: {
		track: {
			type: Track,
			container: "[data-hook~=\"song-track\"]"
		}
	}

});
