var rAF = require("./request-animation-frame");

var cancelAnimationFrame = rAF.cancelAnimationFrame;
var requestAnimationFrame = rAF.requestAnimationFrame;
var queue = [];
var id = null;

function onAnimationFrame(callback) {
	// Abort if callback is already in queue
	if (queue.indexOf(callback) !== -1) {
		return;
	}

	// Add callback to queue
	queue.push(callback);

	// Return if an animation frame is already registered for this queue
	if (id) {
		return;
	}

	// Request animation frame to execute all callbacks
	id = requestAnimationFrame(function runAnimationFrameQueue(time) {
		// Clear animation Frame ID
		id = null;
		// Call queue
		for (var i = 0, l = queue.length; i < l; i++) {
			queue[i](time);
		}
		// Clear queue
		queue = [];
	});
}

function offAnimationFrame(callback) {
	var index = queue.indexOf(callback);

	// Abort if callback is not in queue
	if (index === -1) {
		return;
	}

	// Remove callback from queue
	queue.splice(index, 1);

	// Cancel animation frame if queue is empty now
	if (queue.length === 0) {
		cancelAnimationFrame(id);
		id = null;
	}
}

exports.onAnimationFrame = onAnimationFrame;
exports.offAnimationFrame = offAnimationFrame;
