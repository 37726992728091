export const parallax = (style, multiplier, ratio, quantity) => {
	const currentY = (ratio * quantity) * (multiplier * -1);
	style.translateY(currentY + "px");
};

export const scale = (style, multiplier, ratio) => {
	const value = 1 + (ratio * (multiplier - 1));
	style.scale(value);
};

export const spin = (style, multiplier, ratio) => {
	const value = (ratio * 359) * multiplier;
	style.rotate(value + "deg");
};
