import Component from "../components/component";
import createYouTubePlayer from "./create-youtube-player";

// https://developers.google.com/youtube/iframe_api_reference#Events
const YT_PLAYER_STATE_ENDED     = 0;
const YT_PLAYER_STATE_PLAYING   = 1;
const YT_PLAYER_STATE_PAUSED    = 2;
const YT_PLAYER_STATE_BUFFERING = 3;
const YT_PLAYER_STATE_CUED      = 5;

const ERROR_MESSAGES = {
	"2"   : "The request contains an invalid paramater value.",
	"5"   : "The video could not be played.",
	"100" : "The video could not be found.",
	"101" : "The video is restricted from beeing played."
};

export default Component.extend({

	props: {
		/**
		@property autoplay
		@type boolean
		@default false
		**/
		autoplay: ["boolean", true, false],

		/**
		@property controls
		@type boolean
		@default true
		**/
		controls: ["boolean", true, true],

		/**
		@property onEndedChange
		@type Function
		**/
		onEndedChange: "function",

		/**
		@property onError
		@type Function
		**/
		onError: "function",

		/**
		@property onPlaybackQualityChange
		@type Function
		**/
		onPlaybackQualityChange: "function",

		/**
		@property onPlaybackRateChange
		@type Function
		**/
		onPlaybackRateChange: "function",

		/**
		@property onPlayingChange
		@type Function
		**/
		onPlayingChange: "function",

		/**
		@property onWaitingChange
		@type Function
		**/
		onWaitingChange: "function",

		/**
		@property playbackQuality
		@type number
		@default ""
		**/
		playbackQuality: ["string", true, ""],

		/**
		@property playbackRate
		@type number
		@default 1
		**/
		playbackRate: ["number", true, 1],

		/**
		@property rel
		@type boolean
		@default false
		**/
		rel: ["boolean", true, false],

		/**
		@property showinfo
		@type boolean
		@default false
		**/
		showinfo: ["boolean", true, false],

		/**
		@property videoId
		@type string
		@default ""
		**/
		videoId: ["string", true, ""],

		/**
		@property videoSrc
		@type string
		@default ""
		**/
		videoSrc: ["string", true, ""],

		/**
		@property volume
		@type number
		@default 1
		**/
		volume: ["number", true, 1]
	},

	constructor() {
		this._onPlayerError = this._onPlayerError.bind(this);
		this._onPlayerPlaybackQualityChange = this._onPlayerPlaybackQualityChange.bind(this);
		this._onPlayerPlaybackRateChange = this._onPlayerPlaybackRateChange.bind(this);
		this._onPlayerStateChange = this._onPlayerStateChange.bind(this);
		Component.apply(this, arguments);
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.initYouTubePlayer();
		this.on("change:playbackRate", this._handlePlaybackRateChange);
		this.on("change:videoId", this._handleVideoIdChange);
		this.on("change:volume", this._handleVolumeChange);
	},

	initYouTubePlayer() {
		if (this.el) {
			this.player = new createYouTubePlayer(this.el, {
				videoId: this.videoId,
				events: {
					onError: this._onPlayerError,
					onPlaybackQualityChange: this._onPlayerPlaybackQualityChange,
					onPlaybackRateChange: this._onPlayerPlaybackRateChange,
					onStateChange: this._onPlayerStateChange
				}
			});
		}
		return this;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.initYouTubePlayer();
		return this;
	},

	pause() {
		this.player().then(player => {
			player.pauseVideo();
		});
		return this;
	},

	play() {
		this.player().then(player => {
			player.playVideo();
		});
		return this;
	},

	remove() {
		this.player().then(player => {
			player.stopVideo();
		});
		this.player = undefined;
		return Component.prototype.remove.apply(this, arguments);
	},

	render() {
		const autoplay = this.autoplay ? 1 : 0;
		const controls = this.controls ? 1 : 0;
		const enablejsapi = 1;
		const rel = this.rel ? 1 : 0;
		const showinfo = this.showinfo ? 1 : 0;
		const videoId = this.videoId;
		const src = "https://www.youtube.com/embed/" +
				videoId +
				"?autoplay=" + autoplay +
				"&controls=" + controls +
				"&enablejsapi=" + enablejsapi +
				"&rel=" + rel +
				"&showinfo=" + showinfo;

		this.el = document.createElement("iframe");
		this.el.setAttribute("frameborder", "0");
		this.el.setAttribute("allowfullscreen", "");
		this.el.src = src;
		this.renderComponents();
		this.initYouTubePlayer();
		return this;
	},

	_handleIsMutedChange(target, newVal, options) {
		if (!options || options.src !== "UI") {
			this.el.muted = newVal;
		}
	},

	_handlePlaybackRateChange(target, newVal, options) {
		if (!options || options.src !== "UI") {
			this.el.playbackRate = newVal;
		}
	},

	_handleVideoIdChange(target, newVal) {
		if (this.player) {
			this.player().then(player => {
				player.loadVideoById(newVal);
			});
		}
	},

	_handleVolumeChange(target, newVal, options) {
		if (!options || options.src !== "UI") {
			this.el.volume = newVal;
		}
	},

	_onPlayerError(e) {
		const code = e.data === 150 ? 101 : e.data;
		const message = ERROR_MESSAGES[code] || "An unknown error occured.";
		if (this.onError) {
			this.onError(new Error(message));
		}
	},

	_onPlayerPlaybackQualityChange(e) {
		if (this.onPlaybackQualityChange) {
			this.onPlaybackQualityChange(e.data);
		}
	},

	_onPlayerPlaybackRateChange(e) {
		if (this.onPlaybackRateChange) {
			this.onPlaybackRateChange(e.data);
		}
	},

	_onPlayerStateChange(e) {
		const state = e.data;
		if (state === YT_PLAYER_STATE_ENDED) {
			if (this.isPlaying && this.onPlayingChange) {
				this.onPlayingChange(false);
			}
			this.isPlaying = false;
			if (!this.isEnded && this.onEndedChange) {
				this.onEndedChange(true);
			}
			this.isEnded = true;

		} else if (state === YT_PLAYER_STATE_PLAYING) {
			if (this.isWaiting && this.onWaitingChange) {
				this.onWaitingChange(false);
			}
			this.isWaiting = false;
			if (!this.isPlaying && this.onPlayingChange) {
				this.onPlayingChange(true);
			}
			this.isPlaying = true;

		} else if (state === YT_PLAYER_STATE_PAUSED) {
			if (this.isWaiting && this.onWaitingChange) {
				this.onWaitingChange(false);
			}
			this.isWaiting = false;
			if (this.isPlaying && this.onPlayingChange) {
				this.onPlayingChange(false);
			}
			this.isPlaying = false;

		} else if (state === YT_PLAYER_STATE_BUFFERING) {
			if (!this.isWaiting && this.onWaitingChange) {
				this.onWaitingChange(true);
			}
			this.isWaiting = true;

		} else if (state === YT_PLAYER_STATE_CUED) {

		}
	}

});
