import Component from "../lib/components/component";
import Billboard from "./billboard";
import Chronicle from "./chronicle";
import FigurePoster from "./figure-poster";
import FigureStack from "./figure-stack";
import FigureVideo from "./figure-video";
import Filter from "./filter";
import GalleryPreview from "./gallery-preview";
import HeroVideo from "./hero-video";
import PaginatedList from "./paginated-list";
import ResponsiveVideo from "./responsive-video";
import SectionNav from "./section-nav";
import SectionVideo from "./section-video";
import Song from "./song";
import SongDetails from "./song-details";
import StackCollapsed from "./stack-collapsed";
import ReleaseDetails from "./release-details";
import VideoPlayer from "./video-player";
import assign from "lodash/assign";

const components = {
	"billboard": {
		type: Billboard,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"chronicle": {
		type: Chronicle,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"figure-poster": {
		type: FigurePoster,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"figure-stack": {
		type: FigureStack,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"figure-video": {
		type: FigureVideo
	},
	"filter": {
		type: Filter
	},
	"gallery-preview": {
		type: GalleryPreview
	},
	"hero-video": {
		type: HeroVideo
	},
	"paginated-list": {
		type: PaginatedList,
		bindings: {
			filterUrl: "filterUrl",
			motionManager: "motionManager"
		}
	},
	"release-details": {
		type: ReleaseDetails,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"responsive-video": {
		type: ResponsiveVideo
	},
	"section-nav": {
		type: SectionNav
	},
	"section-video": {
		type: SectionVideo,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"song": {
		type: Song
	},
	"song-details": {
		type: SongDetails
	},
	"stack-collapsed": {
		type: StackCollapsed,
		bindings: {
			motionManager: "motionManager"
		}
	},
	"video-player": {
		type: VideoPlayer
	}
};

const parsePropsFromEl = () => {
	return undefined;
};

/**
@class Editorial
@extends Component
**/
export default Component.extend({

	props: {
		filterUrl: "string",
		motionManager: "state"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.createSectionComponents();
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.createSectionComponents();
		return this;
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.createSectionComponents();
		return this;
	},

	createSectionComponents() {
		if (this.el) {
			const elements = this.el.querySelectorAll("[data-component]");
			Array.prototype.forEach.call(elements, element => {
				const type = element.getAttribute("data-component");
				const config = type && components[type];
				if (config) {
					const props = parsePropsFromEl(element);
					this.createComponent(assign({el: element}, config, {props}));
				}
			});
		}
		return this;
	}

});
