import MotionComponent from "./motion";
import Video from "./video";
import isMobile from "../lib/ua/is-mobile";
import {createResponsiveBackgroundSet} from "../lib/image";

export default MotionComponent.extend({

	props: {
		videoRatio: {
			type: "number",
			required: true,
			default: 0.5625
		},
		videoSrc: {
			type: "string"
		},
		viewportRatio: {
			type: "number"
		}
	},

	derived: {
		fitWidth: {
			deps: ["videoRatio", "viewportRatio"],
			fn() {
				return this.videoRatio > this.viewportRatio;
			}
		}
	},

	bindings: {
		fitWidth: {
			type: "booleanClass",
			name: "Poster--fitWidth"
		}
	},

	initialize() {
		MotionComponent.prototype.initialize.apply(this, arguments);
		this.initVideoOrBackgroundImages();
	},

	didMove(rect) {
		this.viewportRatio = rect.height / rect.width;
		if (this.images) {
			this.images.update(rect.width);
		}
	},

	initBackgroundImages() {
		const elements = this.el ? this.queryAll("[data-srcset]") : null;
		if (elements && elements.length) {
			const {width} = this.motionManager.getRectForComponent(this) || {};
			this.images = createResponsiveBackgroundSet(elements);
			this.images.resize(width);
		}
		return this;
	},

	initVideo() {
		if (this.el && this.videoSrc) {
			this.video = this.createComponent({
				type: Video,
				props: {
					autoplay: true,
					controls: false,
					defaultMuted: true,
					loop: true,
					src: this.videoSrc
				}
			});
			this.listenTo(this.video, "change:videoHeight", this.updateVideoRatio);
			this.listenTo(this.video, "change:videoWidth", this.updateVideoRatio);
			this.video.render();
			const img = this.queryByHook("poster-image");
			if (img) {
				img.parentNode.replaceChild(this.video.el, img);
			} else {
				this.el.appendChild(this.video.el);
			}
		}
		return this;
	},

	initVideoOrBackgroundImages() {
		this.videoSrc = this.el ? this.el.getAttribute("data-video") : undefined;
		if (!isMobile && this.videoSrc) {
			this.initVideo();
		} else {
			this.initBackgroundImages();
		}
		return this;
	},

	mount() {
		MotionComponent.prototype.mount.apply(this, arguments);
		this.initVideoOrBackgroundImages();
		return this;
	},

	remove() {
		this.images = undefined;
		this.video = undefined;
		return MotionComponent.prototype.remove.apply(this, arguments);
	},

	render() {
		MotionComponent.prototype.render.apply(this, arguments);
		this.initVideoOrBackgroundImages();
		return this;
	},

	updateVideoRatio() {
		if (this.video && this.video.videoWidth && this.video.videoHeight) {
			this.videoRatio = this.video.videoHeight / this.video.videoWidth;
		}
	}

});
