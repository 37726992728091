import Component from "../lib/components/component";
import Distributors from "./distributors";
import MotionComponent from "./motion";
import TrackList from "./track-list";

const ReleaseDetailsAside = Component.extend({

	props: {
		isSticky: "boolean",
		positionClass: "string"
	},

	bindings: {
		isSticky: {
			type: "booleanClass"
		},
		positionClass: {
			type: "class"
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.initDistributors();
	},

	getHeight() {
		if (this.el) {
			const inner = this.queryByHook("release-details-aside-inner");
			return inner && inner.offsetHeight || 0;
		}
		return 0;
	},

	initDistributors() {
		if (this.el) {
			const container = this.queryByHook("release-details-aside-distributors");
			if (container && container.children.length === 1) {
				this.createComponent({
					type: Distributors,
					el: container.children[0],
					bindings: {
						motionManager: "motionManager"
					}
				}, "distributors");
			}
		}
		return this;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.initDistributors();
		return this;
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.initDistributors();
		return this;
	}

});

export default MotionComponent.extend({

	props: {
		asideIsSticky: "boolean",
		asidePositionClass: "string"
	},

	components: {
		aside: {
			type: ReleaseDetailsAside,
			hook: "release-details-aside",
			bindings: {
				isSticky: "asideIsSticky",
				positionClass: "asidePositionClass"
			}
		},
		tracks: {
			type: TrackList,
			container: "[data-hook~=\"release-details-tracks\"]"
		}
	},

	willMove(rect) {
		if (this.lastRectHeight !== rect.height) {
			this.asideHeight = this.aside.getHeight();
			this._newAsideIsSticky = (this.asideHeight && rect.height > this.asideHeight);
		}
	},

	didMove(rect, data) {
		this.asideIsSticky = this._newAsideIsSticky;

		if (!this.asideIsSticky) {
			this.unset("asidePositionClass");
			return this;
		}

		const scrollY = data.viewport.y - rect.y;
		if (scrollY <= 0) {
			this.asidePositionClass = "isTop";
			return this;
		}

		const height = this.lastRectHeight = rect.height || 0;
		const bottomScrollY = height - scrollY;
		const asideHeight = this.asideHeight || 0;

		if (bottomScrollY < asideHeight) {
			this.asidePositionClass = "isBottom";
			return this;
		}

		this.asidePositionClass = "isMiddle";
	}

});
