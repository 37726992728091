import Component from "../lib/components/component";

/**
Manages an `HTMLMediaElement` in the DOM.

@class Media
@extends Component
**/
export default Component.extend({

	// -- Public Properties ----------------------------------------------------

	props: {
		/**
		@property autoplay
		@type boolean
		@default false
		**/
		autoplay: ["boolean", true, false],

		/**
		@property buffered
		@type number
		@default 0
		**/
		buffered: ["number", true, 0],

		/**
		@property controls
		@type boolean
		@default false
		**/
		controls: ["boolean", true, false],

		/**
		@property currentTime
		@type number
		@default 0
		**/
		currentTime: ["number", true, 0],

		/**
		@property defaultMuted
		@type boolean
		@default false
		**/
		defaultMuted: ["boolean", true, false],

		/**
		@property duration
		@type number
		@default 0
		**/
		duration: ["number", true, 0],

		/**
		@property isEnded
		@type boolean
		@default false
		**/
		isEnded: ["boolean", true, false],

		/**
		@property isLoaded
		@type boolean
		@default false
		**/
		isLoaded: ["boolean", true, false],

		/**
		@property isPlaying
		@type boolean
		@default false
		**/
		isPlaying: ["boolean", true, false],

		/**
		@property isSeeking
		@type boolean
		@default false
		**/
		isSeeking: ["boolean", true, false],

		/**
		@property isWaiting
		@type boolean
		@default false
		**/
		isWaiting: ["boolean", true, false],

		/**
		@property loop
		@type boolean
		@default false
		**/
		loop: ["boolean", true, false],

		/**
		@property muted
		@type boolean
		@default false
		**/
		muted: ["boolean", true, false],

		/**
		@property playbackRate
		@type number
		@default 1
		**/
		playbackRate: ["number", true, 1],

		/**
		Reflects the preload HTML attribute, indicating what data should be
		preloaded, if any. Possible values are: 'none', 'metadata', 'auto'.

		@property preload
		@type string
		@default "auto"
		**/
		preload: ["string", true, "auto"],

		/**
		Is a DOMString that reflects the src HTML attribute, which contains the
		URL of a media resource to use.

		@property src
		@type string
		@default ""
		**/
		src: ["string", true, ""],

		/**
		@property volume
		@type number
		@default 1
		**/
		volume: ["number", true, 1]
	},

	bindings: {
		autoplay: {
			type: "booleanAttribute"
		},
		controls: {
			type: "booleanAttribute"
		},
		muted: {
			type: "booleanAttribute"
		},
		loop: {
			type: "booleanAttribute"
		},
		preload: {
			type: "attribute",
			name: "preload"
		}
	},

	events: {
		"abort": "_onAbort",
		"canplay": "_onCanplay",
		"canplaythrough": "_onCanplaythrough",
		"durationchange": "_onDurationchange",
		"emptied": "_onEmptied",
		"ended": "_onEnded",
		"error": "_onError",
		"loadeddata": "_onLoadeddata",
		"loadstart": "_onLoadstart",
		"pause": "_onPause",
		"play": "_onPlay",
		"playing": "_onPlaying",
		"progress": "_onProgress",
		"ratechange": "_onRatechange",
		"seeked": "_onSeeked",
		"seeking": "_onSeeking",
		"stalled": "_onStalled",
		"timeupdate": "_onTimeupdate",
		"volumechange": "_onVolumechange",
		"waiting": "_onWaiting"
	},

	// -- Lifecycle Methods ----------------------------------------------------

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.on("change:playbackRate", this._handlePlaybackRateChange);
		this.on("change:src", this._handleSrcChange);
		this.on("change:volume", this._handleVolumeChange);
	},

	// -- Public Methods -------------------------------------------------------

	/**
	@method bind
	@chainable
	**/
	mount() {
		Component.prototype.mount.apply(this, arguments);
		//this._parseMediaElement(this.el);
		return this;
	},

	/**
	Determines whether the specified media type can be played back.

	Returns one of the following Strings:
	- **probably** if the specified type appears to be playable.
	- **maybe** if it's impossible to tell whether the type is playable without
	playing it.
	- The empty string if the specified type definitely cannot be played.

	@method canPlayType
	@param {string} type
	@return {string} "probably"|"maybe"|""
	**/
	canPlayType(type) {
		return this.el.canPlayType(type);
	},

	/**
	Reset the media element and restart selecting the media resource. Any
	pending events are discarded. How much media data is fetched is still
	affected by the preload attribute. This method can be useful for releasing
	resources after any src attribute and source element descendants have been
	removed. Otherwise, it is usually unnecessary to use this method, unless
	required to rescan source element children after dynamic changes.

	@method load
	@chainable
	**/
	load() {
		this.el.load();
		return this;
	},

	/**
	@method pause
	@chainable
	**/
	pause() {
		this.el.pause();
		return this;
	},

	/**
	@method pause
	@chainable
	**/
	play() {
		this.el.play();
		return this;
	},

	/**
	Directly seek to the given time.

	@method seek
	@param {number} time
	@chainable
	**/
	seek(time) {
		if (typeof this.el.fastSeek === "function") {
			this.el.fastSeek(time);
		} else {
			this.el.currentTime = time;
		}
		return this;
	},

	// -- Protected Methods ----------------------------------------------------

	/**
	@private
	@method _parseMediaElement
	**/
	_parseMediaElement(el) {
		this.set({
			currentTime: el.currentTime,
			duration: el.duration
		}, {src: "UI"});
	},

	// -- Protected Event Handlers ---------------------------------------------

	/**
	@method _handlePlaybackRateChange
	@param {Component} target
	@param {number} newVal
	@param {Object} options
	**/
	_handlePlaybackRateChange(target, newVal, options) {
		if (!options || options.src !== "UI") {
			this.el.playbackRate = newVal;
		}
	},

	/**
	@method _handleSrcChange
	@param {Component} target
	@param {number} newVal
	@param {Object} options
	**/
	_handleSrcChange(target, newVal) {
		if (newVal) {
			this.el.src = newVal;
		}
	},

	/**
	@method _handleVolumeChange
	@param {Component} target
	@param {number} newVal
	@param {Object} options
	**/
	_handleVolumeChange(target, newVal, options) {
		if (!options || options.src !== "UI") {
			this.el.volume = newVal;
		}
	},

	/**
	The user agent stops fetching the media data before it is completely
	downloaded, but not due to an error.

	@private
	@method _onAbort
	@param {Event} e
	**/
	_onAbort() {
		this.trigger("abort", this);
	},

	/**
	The user agent can resume playback of the media data, but estimates that if
	playback were to be started now, the media resource could not be rendered at
	the current playback rate up to its end without having to stop for further
	buffering of content.

	@private
	@method _onCanplay
	@param {Event} e
	**/
	_onCanplay() {
		this.trigger("canplay", this);
	},

	/**
	The user agent estimates that if playback were to be started now, the media
	resource could be rendered at the current playback rate all the way to its
	end without having to stop for further buffering.

	@private
	@method _onCanplaythrough
	@param {Event} e
	**/
	_onCanplaythrough() {
		this.trigger("canplaythrough", this);
	},

	/**
	The duration attribute has just been updated.

	@private
	@method _onDurationchange
	@param {Event} e
	**/
	_onDurationchange() {
		this.set("duration", this.el.duration, {src: "UI"});
	},

	/**
	A media element whose networkState was previously not in the NETWORK_EMPTY
	state has just switched to that state (either because of a fatal error
	during load that’s about to be reported, or because the load() method was
	invoked while the resource selection algorithm was already running).

	@private
	@method _onEmptied
	@param {Event} e
	**/
	_onEmptied() {
		
	},

	/**
	Playback has stopped because the end of the media resource was reached.

	@private
	@method _onEnded
	@param {Event} e
	**/
	_onEnded() {
		this.isPlaying = false;
		this.isEnded = true;
	},

	/**
	An error occurs while fetching the media data.

	@private
	@method _onError
	@param {Event} e
	**/
	_onError() {
		this.trigger("error", this, this.el.error);
	},

	/**
	The user agent can render the media data at the current playback position
	for the first time.

	@private
	@method _onLoadeddata
	@param {Event} e
	**/
	_onLoadeddata() {
		this.trigger("loadeddata", this);
	},

	/**
	The user agent begins looking for media data, as part of the resource
	selection algorithm.

	@private
	@method _onLoadstart
	@param {Event} e
	**/
	_onLoadstart() {
		this.trigger("loadstart", this);
	},

	/**
	The element has been paused. Fired after the pause() method has returned.

	@private
	@method _onPause
	@param {Event} e
	**/
	_onPause() {
		this.isWaiting = false;
		this.isPlaying = false;
	},

	/**
	The element is no longer paused. Fired after the play() method has returned,
	or when the autoplay attribute has caused playback to begin.

	@private
	@method _onPlay
	@param {Event} e
	**/
	_onPlay() {
		this.isPlaying = true;
	},

	/**
	Playback is ready to start after having been paused or delayed due to lack
	of media data.

	@private
	@method _onPlaying
	@param {Event} e
	**/
	_onPlaying() {
		this.isWaiting = false;
	},

	/**
	The user agent is fetching media data.

	@private
	@method _onProgress
	@param {Event} e
	**/
	_onProgress() {
		const timeRanges = this.el.buffered;
		let buffered = 0;
		for (let i = 0; i < timeRanges.length; i++) {
			buffered = buffered + (timeRanges.end(i) - timeRanges.start(i));
		}
		this.buffered = buffered / this.duration;
		if (this.buffered === 1 || this.buffered === Infinity) {
			this.isLoaded = true;
		}
	},

	/**
	Either the defaultPlaybackRate or the playbackRate attribute has just been
	updated.

	@private
	@method _onRatechange
	@param {Event} e
	**/
	_onRatechange() {
		this.set("playbackRate", this.el.playbackRate, {src: "UI"});
	},

	/**
	One or both of the videoWidth and videoHeight attributes have just been
	updated.

	@private
	@method _onResize
	@param {Event} e
	**/
	_onResize() {
		this.set({
			videoHeight: this.el.videoHeight,
			videoWidth: this.el.videoWidth
		}, {src: "UI"});
	},

	/**
	The seeking IDL attribute changed to false after the current playback
	position was changed.

	@private
	@method _onSeeked
	@param {Event} e
	**/
	_onSeeked() {
		this.isSeeking = false;
	},

	/**
	The seeking IDL attribute changed to true, and the user agent has started
	seeking to a new position.

	@private
	@method _onSeeking
	@param {Event} e
	**/
	_onSeeking() {
		this.isSeeking = true;
	},

	/**
	The user agent is trying to fetch media data, but data is unexpectedly not
	forthcoming.

	@private
	@method _onStalled
	@param {Event} e
	**/
	_onStalled() {
		this.trigger("stalled", this);
	},

	/**
	The current playback position changed as part of normal playback or in an
	especially interesting way, for example discontinuously.

	@private
	@method _onTimeupdate
	@param {Event} e
	**/
	_onTimeupdate() {
		this.set("currentTime", this.el.currentTime, {src: "UI"});
	},

	/**
	Either the volume attribute or the muted attribute has changed. Fired after
	the relevant attribute's setter has returned.

	@private
	@method _onVolumechange
	@param {Event} e
	**/
	_onVolumechange() {
		this.set({
			muted: this.el.muted,
			volume: this.el.volume
		}, {src: "UI"});
	},

	/**
	Playback has stopped because the next frame is not available, but the user
	agent expects that frame to become available in due course.

	@private
	@method _onWaiting
	@param {Event} e
	**/
	_onWaiting() {
		this.isWaiting = true;
	}

});
