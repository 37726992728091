var prefix = require("vendor-prefix");

var transform = prefix("transform");
var hasTranslate3d = false;
var el, val;

if (transform && window.getComputedStyle) {
	// from: https://gist.github.com/lorenzopolidori/3794226
	el = document.createElement("div");
	el.style[transform] = "translate3d(1px,1px,1px)";
	document.body.insertBefore(el, null);
	val = getComputedStyle(el).getPropertyValue(prefix.dash("transform"));
	document.body.removeChild(el);
	hasTranslate3d = val && val.length && val !== "none";
}

module.exports = hasTranslate3d;
