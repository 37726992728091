var State = require("ampersand-state");
var actionHandlerMixin = require("../action-handler-mixin");
var assign = require("lodash/assign");
var createBindingsStore = require("../state-bindings/create-bindings-store");
var domBindings = require("ampersand-dom-bindings");
var domify = require("domify");
var events = require("events-mixin");
var getPath = require("lodash/get");
var mapStateToProps = require("../state-bindings/map-state-to-props");
var isString = require("lodash/isString");
var matches = require("matches-selector");
var result = require("lodash/result");
var uniqueId = require("lodash/uniqueId");

function hookSelector(hook) {
	return hook && "[data-hook~=\"" + hook + "\"]" || undefined;
}

function querySelector(element, selector) {
	return selector && element.querySelector(selector) || undefined;
}

function queryComponentElByContainer(parent, config) {
	var container = config && config.container ? querySelector(parent, config.container) : parent;
	return container && container.children.length === 1 && container.children[0] || undefined;
}

function queryComponentElBySelector(parent, config) {
	var selector = config && (config.selector || hookSelector(config.hook));
	return querySelector(parent, selector);
}

function queryComponentEl(parent, config) {
	if (config && (config.selector || config.hook)) {
		return queryComponentElBySelector(parent, config);
	}
	return queryComponentElByContainer(parent, config);
}

var BaseState = State.extend({
	dataTypes: {
		collection: {
			set: function (newVal) {
				return {
					val: newVal,
					type: newVal && newVal.isCollection ? "collection" : typeof newVal
				};
			},
			compare: function (collectionA, collectionB) {
				return collectionA === collectionB;
			}
		},
		element: {
			set: function (newVal) {
				return {
					val: newVal,
					type: newVal instanceof Element ? "element" : typeof newVal
				};
			},
			compare: function (elementA, elementB) {
				return elementA === elementB;
			}
		},
		function: {
			set: function (newVal) {
				return {
					val: newVal,
					type: typeof newVal
				};
			},
			compare: function (functionA, functionB) {
				return functionA === functionB;
			}
		}
	},
	props: {
		el: "element"
	},
	session: {
		_rendered: {
			type: "boolean",
			required: true,
			default: false
		}
	},
	derived: {
		rendered: {
			deps: ["_rendered"],
			fn: function () {
				if (this._rendered) {
					this.trigger("render", this);
					return true;
				}
				this.trigger("remove", this);
				return false;
			}
		}
	}
});

/**
@class Component
@extends State
**/
function Component(attrs) {
	attrs = attrs || {};
	this.cid = uniqueId("component");
	var parent = attrs.parent;
	delete attrs.parent;
	var parse = attrs.parse;
	delete attrs.parse;
	BaseState.call(this, attrs, {init: false, parent: parent, parse: parse});
	this.on("change:el", this._handleElementChange, this);
	this._initBindings();
	if (attrs.template) {
		this.template = attrs.template;
	}
	this._cache.rendered = false; // prep `rendered` derived cache immediately
	this.initialize.apply(this, arguments);
}

/**
@example
	const greetingMixin = {
		sayHi(name) {
			console.log("Hi" + (name ? " " + name : "") + "!");
		}
	};

	const MyComponent = Component.extend(greetingMixin, {
		sayHiToPaul() {
			this.sayHi("Paul");
		}
	});

	MyComponent.sayHiToPaul();
	// "Hi Paul!"

@static
@method extend
@param {Object} props One or more hashes of inheritable prototype members for
	the resulting class.
@return {Function} Factory function for the new class.
**/
Component.extend = BaseState.extend;

Component.prototype = Object.create(BaseState.prototype);

var QUERY_NO_EL_MESSAGE = "Query cannot be performed as this.el " +
		"is not defined. Ensure that the view has been rendered.";

assign(Component.prototype, actionHandlerMixin, {

	// -- Public Methods -------------------------------------------------------

	/**
	@method createComponent
	@param {Object} config
		@param {Function} config.type Component factory function
		@param {String} [config.name] Name for the component
		@param {String} [config.selector] Selector for components `el`
		@param {Object} [config.props]
	@param {String} [name] Name for the component. This will win over
		`config.name` if both are specified.
	@return {Component} The newly created component
	**/
	createComponent: function (config, name) {
		config = config || {};
		name = name || config.name;
		var bindings = config.bindings;
		var el = config.el;
		var parse = config.parse;
		var props = typeof config.props === "function" ? config.props.call(this) : config.props;
		var Factory = config.type;
		if (!el && this.el) {
			el = queryComponentEl(this.el, config);
		}
		var initialProps = mapStateToProps(this, bindings, props);
		var component = new Factory(assign({parent: this, el: el, parse: parse}, initialProps));
		this.registerComponent(component, name);
		this.registerBindingsForComponent(component, bindings);
		return component;
	},

	/**
	Set callbacks, where `this.events` is a hash of
	`{"event selector": "callback"}` pairs. Callbacks will be bound to the view,
	with `this` set properly. Uses event delegation for efficiency. Omitting the
	selector binds the event to `this.el`. This only works for delegate-able
	events: not `focus`, `blur`, and not `change`, `submit`, and `reset` in
	Internet Explorer.

	@example
		const MyComponent = Component.extend({
			events: {
				"mousedown .title" : "edit",
				"click .button"    : "save",
				"click .open"      : function (e) { ... }
			},
			edit(e) { ... },
			save(e) { ... }
		});

	@method delegateEvents
	@param {Object} [events]
	@chainable
	**/
	delegateEvents: function (events) {
		if (!(events || (events = result(this, "events")))) {
			return this;
		}
		this.undelegateEvents();
		for (var key in events) {
			if (events.hasOwnProperty(key)) {
				this.eventManager.bind(key, events[key]);
			}
		}
		return this;
	},

	/**
	@method mountComponents
	@chainable
	**/
	mountComponents: function (components) {
		components = components || result(this, "components");
		if (!components) {
			return this;
		}

		var key, config, component, componentEl, container;

		for (key in components) {
			if (components.hasOwnProperty(key)) {
				config = components[key];
				component = this[key];
				componentEl = queryComponentEl(this.el, config);

				if (componentEl) {
					this.mountComponent(component, componentEl);

				} else if (!config.selector && !config.hook) {
					container = config.container ? querySelector(this.el, config.container) : this.el;
					if (container) {
						this.renderComponent(component, container);
					} else {
						throw new Error("Could not find 'container' for child component '" + key + "'.");
					}

				} else {
					throw new Error("Could not find 'el' for child component '" + key + "'.");
				}
			}
		}
		return this;
	},

	/**
	@method mountComponent
	@param {Object} component
	@param {Object} config
	@return {Component} The mounted component
	**/
	mountComponent: function (component, el) {
		if (!el) {
			throw new Error("Missing 'el' for mounting child component.");
		}
		this.registerComponent(component);
		if (typeof component.mount === "function") {
			component.mount(el);
		} else {
			component.el = el;
		}
		return component;
	},

	/**
	@method mount
	@param {Element} element The element that will be set to `this.el`.
	@chainable
	**/
	mount: function (el) {
		if (!el) {
			throw new Error("Missing 'el' for mounting Component.");
		}

		if (this._rendered) {
			throw new Error("Only non rendered Components can be mounted.");
		}

		this.el = el;
		this.mountComponents();
		this._rendered = true;
		return this;
	},

	/**
	Returns an array of elements based on CSS selector scoped to this.el
	if you pass an empty string it return `this.el`. Also includes root
	element.

	@method queryAll
	@param {String} selector
	@return {Array} An array containing matched elements
	**/
	queryAll: function (selector) {
		if (!this.el) {
			throw new Error(QUERY_NO_EL_MESSAGE);
		}
		if (!selector) {
			return [this.el];
		}
		var res = [];
		if (matches(this.el, selector)) {
			res.push(this.el);
		}
		return res.concat(Array.prototype.slice.call(this.el.querySelectorAll(selector)));
	},

	/**
	Convenience method for fetching all elements by their’s `data-hook`
	attribute.

	@method queryAllByHook
	@param {String} hook
	@return {Array}
	**/
	queryAllByHook: function (hook) {
		return this.queryAll(hookSelector(hook));
	},

	/**
	Convenience method for fetching element by it’s `data-hook` attribute. Also
	tries to match against root element. Supports matching "one" of several
	space separated hooks.

	@method queryByHook
	@param {String} hook
	@return {Element|undefined} element
	**/
	queryByHook: function (hook) {
		return this.query(hookSelector(hook));
	},

	/**
	Get an single element based on CSS selector scoped to this.el if you pass an
	empty string it return `this.el`. If you pass an element we just return it
	back. This lets us use `get` to handle cases where users can pass a selector
	or an already selected element.

	@method query
	@param {String} selector
	@return {Element|undefined} element
	**/
	query: function (selector) {
		if (!this.el) {
			throw new Error(QUERY_NO_EL_MESSAGE);
		}
		if (!selector) {
			return this.el;
		}
		if (typeof selector === "string") {
			if (matches(this.el, selector)) {
				return this.el;
			}
			return this.el.querySelector(selector) || undefined;
		}
		return selector;
	},

	/**
	@method removeComponent
	@param {Component} component Component that will be removed.
	@param {String} [name]
	@return {Component} The removed component
	**/
	removeComponent: function (component, name) {
		component.remove();
		this.unregisterComponent(component, name);
		return component;
	},

	/**
	@method renderComponents
	@chainable
	**/
	renderComponents: function (components) {
		components = components || result(this, "components");
		if (!components) {
			return this;
		}

		var key, config, component, componentEl, container;

		for (key in components) {
			if (components.hasOwnProperty(key)) {
				config = components[key];
				component = this[key];
				if (config.selector || config.hook) {
					componentEl = queryComponentElBySelector(this.el, config);
					if (componentEl) {
						component.el = componentEl;
					} else {
						throw new Error("Could not find 'el' for child component '" + key + "'.");
					}
				}
				component.render();
				if (!config.selector && !config.hook) {
					container = config.container ? querySelector(this.el, config.container) : this.el;
					if (container) {
						container.appendChild(component.el);
					} else {
						throw new Error("Could not find 'container' for child component '" + key + "'.");
					}
				}
			}
		}
		return this;
	},

	/**
	@method renderComponent
	@param {Component} component Component that will be rendered.
	@param {String|Element} [container] Element that the rendered component will
		be appended to. Defaults to `this.el`.
	@return {Component} The rendered component
	**/
	renderComponent: function (component, container) {
		if (typeof container === "string") {
			container = this.query(container);
		}
		if (!container) {
			container = this.el;
		}
		this.registerComponent(component);
		container.appendChild(component.render().el);
		return component;
	},

	/**
	@method renderTemplate
	@param {Object} [context]
	@param {String|Function} [template]
	@return {String|Element} The rendered template
	**/
	renderTemplate: function (context, template) {
		context = context || this;
		template = template || this.template;
		if (!template) {
			throw new Error("Template string or function needed.");
		}
		var data = {view: this};
		return isString(template) ? template : template.call(this, context, {data: data});
	},

	/**
	Shortcut for doing everything we need to do to render and fully replace
	current root element. Either define a `template` property of your view or
	pass in a template directly. The template can either be a string or a
	function. If it’s a function it will be passed the `context` argument.

	@method renderWithContext
	@param {Object} [context]
	@param {String|Function} [template]
	@chainable
	**/
	renderWithContext: function (context, template) {
		var parent = this.el && this.el.parentNode;
		var output = this.renderTemplate(context, template);
		var newDom = isString(output) ? domify(output) : output;
		if (parent) {
			parent.replaceChild(newDom, this.el);
		}
		if (newDom.nodeName === "#document-fragment") {
			throw new Error("Components can only have one root element, including comment nodes.");
		}
		this.el = newDom;
		return this;
	},

	/**
	@method registerBindingsForComponent
	@param {Component} component
	@param {Object} bindings
	@chainable
	**/
	registerBindingsForComponent: function (component, bindings) {
		if (!bindings) {
			return this;
		}
		if (!this._componentBindings) {
			this._initComponentBindings();
		}
		var unsubscribe = this._componentBindings.add(component, bindings);
		component.once("remove", unsubscribe);
		return this;
	},

	/**
	@method registerComponent
	@param {Component} component
	@param {String} [name]
	@return {Component} The registered component
	**/
	registerComponent: function (component, name) {
		var components = this._components || (this._components = []);
		if (components.indexOf(component) !== -1) {
			// Don’t register a single component multiple times
			return component;
		}
		components.push(component);
		if (name) {
			// If it’s a named component, store a reference under the given name.
			this._safeSet(name, component);
		}
		if (!component.parent) {
			// Set the parent reference if it has not been set
			component.parent = this;
		}
		this.trigger("register", this, component, name);
		return component;
	},

	/**
	Clears all callbacks previously bound to the view with `delegateEvents`.
	You usually don’t need to use this, but may wish to if you have multiple
	Backbone views attached to the same DOM element.

	@method undelegateEvents
	@chainable
	**/
	undelegateEvents: function () {
		this.eventManager.unbind();
		return this;
	},

	/**
	@method unregisterComponent
	@param {Component} component
	@param {String} [name]
	@return {Component} The component that was unregistered
	**/
	unregisterComponent: function (component, name) {
		var components = this._components;
		var index = components ? components.indexOf(component) : -1;
		if (index === -1) {
			// Subcomponent is not registered on this component
			return component;
		}
		this.trigger("unregister", this, component, name);
		if (component.parent === this) {
			component.parent = undefined;
		}
		if (name && this[name] === component) {
			delete this[name];
		}
		components.splice(index, 1);
		return component;
	},

	// -- Private Methods ------------------------------------------------------

	/**
	@private
	@method _applyBindingsForKey
	@chainable
	**/
	_applyBindingsForKey: function (name) {
		if (this._componentBindings) {
			this._componentBindings.update(name);
		}
		if (this.el && this._domBindings) {
			var el = this.el;
			var handlers = this._domBindings.getGrouped(name);
			for (var item in handlers) {
				if (handlers.hasOwnProperty(item)) {
					var value = getPath(this, item);
					handlers[item].forEach(function (handler) {
						handler(el, value, item.split(".").pop());
					});
				}
			}
		}
		return this;
	},

	/**
	@private
	@method _initBindings
	@chainable
	**/
	_initBindings: function () {
		if (this.bindingsSet) {
			return this;
		}
		this._initDomBindings();
		this._initComponents();
		if (this.el) {
			this._handleElementChange();
		}
		this.bindingsSet = true;
		return this;
	},

	/**
	@private
	@method _initComponentBindings
	@chainable
	**/
	_initComponentBindings: function () {
		this._componentBindings = createBindingsStore(this);
		this._observePropertyChangesForBindings();
		return this;
	},

	/**
	@private
	@method _initComponents
	@param {Object} [components] Hash of component configs to be initialized.
		Defaults to `this.components`.
	@chainable
	**/
	_initComponents: function (components) {
		components = components || result(this, "components");
		if (!components) {
			return this;
		}
		for (var name in components) {
			if (components.hasOwnProperty(name)) {
				this.createComponent(components[name], name);
			}
		}
		return this;
	},

	/**
	@private
	@method _initDomBindings
	@chainable
	**/
	_initDomBindings: function () {
		if (this.bindings) {
			this._domBindings = domBindings(this.bindings, this);
			this._observePropertyChangesForBindings();
		}
		return this;
	},

	/**
	@private
	@method _observePropertyChangesForBindings
	@chainable
	**/
	_observePropertyChangesForBindings: function () {
		if (!this._notifyBindingsOfPropertyChange) {
			var that = this;
			this._notifyBindingsOfPropertyChange = function (eventName) {
				if (eventName.slice(0, 7) === "change:") {
					that._applyBindingsForKey(eventName.split(":")[1]);
				}
			};
			this.on("all", this._notifyBindingsOfPropertyChange);
		}
		return this;
	},

	/**
	@private
	@method _removeBindings
	@chainable
	**/
	_removeBindings: function () {
		if (!this.bindingsSet) {
			return this;
		}
		if (this._notifyBindingsOfPropertyChange) {
			this.off("all", this._notifyBindingsOfPropertyChange);
			delete this._notifyBindingsOfPropertyChange;
		}
		if (this._components) {
			this._components.forEach(function (component) {
				this.removeComponent(component);
			}, this);
		}
		delete this._componentBindings;
		// TODO: Is it neccessary to remove single properties inside
		// KeyTreeStore objects in order to properly garbage collect?
		delete this._domBindings;
		this.bindingsSet = false;
		return this;
	},

	/**
	Removes this view by taking the element out of the DOM, and removing any
	applicable events listeners.

	@private
	@method _remove
	@chainable
	**/
	_remove: function () {
		if (this.el && this.el.parentNode) {
			this.el.parentNode.removeChild(this.el);
		}
		this._rendered = false;
		this._removeBindings();
		return this;
	},

	/**
	**render** is the core function that your view can override. Its job is to
	populate its element (`this.el`), with the appropriate HTML.

	@private
	@method _render
	@chainable
	**/
	_render: function () {
		this.renderWithContext(this);
		this.renderComponents();
		this._rendered = true;
		return this;
	},

	// -- Private Event Handlers -----------------------------------------------

	/**
	Change the view’s element (`this.el` property), including event
	re-delegation.

	@private
	@method _handleElementChange
	@chainable
	**/
	_handleElementChange: function () {
		if (this.eventManager) {
			this.eventManager.unbind();
		}
		this.eventManager = events(this.el, this);
		this.delegateEvents();
		this._applyBindingsForKey();
		return this;
	}

});

Object.defineProperty(Component.prototype, "remove", {
	get: function () {
		return this._remove;
	},
	set: function (fn) {
		this._remove = function () {
			fn.apply(this, arguments);
			this._rendered = false;
			return this;
		};
	}
});

Object.defineProperty(Component.prototype, "render", {
	get: function () {
		return this._render;
	},
	set: function (fn) {
		this._render = function () {
			fn.apply(this, arguments);
			this._rendered = true;
			return this;
		};
	}
});

module.exports = Component;
