import assign from "lodash/assign";
import * as defaultEffects from "./effects";
import {createElementAnimation} from "./element-animation";

const parseConfigValue = (value) => {
	const parsed = parseFloat(value);
	// parsed !== parsed is equivalent to Number.isNaN(parsed)
	return parsed !== parsed ? 0 : parsed;
};

const parseConfig = (config) => {
	if (config.indexOf(",") !== -1) {
		return config.split(",").map(parseConfigValue);
	}
	return parseConfigValue(config);
};

export const createParallax = (element, customEffects) => {
	if (!(element instanceof HTMLElement)) {
		throw new Error("Expected an HTMLElement for 'createParallax'.");
	}

	const effectsByName = customEffects ? assign({}, defaultEffects, customEffects) : defaultEffects;
	const elementAnimation = createElementAnimation();

	let currentEffects = [];
	let currentRatio;

	const parallax = {

		el() {
			return element;
		},

		draw(ratio, ...options) {
			if (ratio !== currentRatio) {
				for (let i = 0; i < currentEffects.length; i++) {
					currentEffects[i](elementAnimation, ratio, ...options);
				}
				elementAnimation.draw(element);
				currentRatio = ratio;
			}
			return parallax;
		},

		effect(effectFn, config) {
			if (typeof effectFn === "string") {
				effectFn = effectsByName[effectFn];
			}
			currentEffects.push((anim, ...args) => effectFn(anim, config, ...args));
			return parallax;
		},

		parse() {
			currentEffects = [];

			for (let effectName in effectsByName) {
				if (effectsByName.hasOwnProperty(effectName)) {
					const attrName = "data-parallax" + (effectName == "parallax" ? "" : "-" + effectName);
					const config = element.getAttribute(attrName);
					if (config) {
						parallax.effect(effectName, parseConfig(config));
					}
				}
			}

			return parallax;
		}
	};

	// Init
	parallax.parse();
	elementAnimation.translateZ(0);

	return parallax;
};

export const createParallaxSet = (elements, customEffects) => {
	if (!Array.isArray(elements)) {
		throw new Error("Expected an array of HTMLElements for 'createParallaxGroup'.");
	}

	let currentRatio;
	let items;

	const parallaxSet = {

		draw(ratio, ...options) {
			if (ratio !== currentRatio) {
				for (let i = 0; i < items.length; i++) {
					items[i].draw(ratio, ...options);
				}
				currentRatio = ratio;
			}
			return parallaxSet;
		},

		find(el) {
			for (let i = 0; i < items.length; i++) {
				if (items[i].el() === el) {
					return items[i];
				}
			}
			return null;
		},

		forEach(iterator = () => {}) {
			for (let i = 0; i < items.length; i++) {
				iterator(items[i], i, items);
			}
			return parallaxSet;
		},

		parse() {
			for (let i = 0; i < items.length; i++) {
				items[i].parse();
			}
			return parallaxSet;
		}
	};

	// Init
	items = elements.map((element) => createParallax(element, customEffects));

	return parallaxSet;
};
