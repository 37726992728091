import Component from "../lib/components/component";
import Poster from "./poster";

export default Component.extend({

	props: {
		motionManager: "state"
	},

	components: {
		poster: {
			type: Poster,
			container: "[data-hook~=\"figure-media\"]",
			bindings: {
				motionManager: "motionManager"
			}
		}
	}

});
