import Component from "../lib/components/component";
import assign from "lodash/assign";

const PlaylistItem = Component.extend({

	props: {
		isPlaying: "boolean",
		onSelect: "function",
		title: "string",
		videoId: "string"
	},

	bindings: {
		isPlaying: {
			type: "booleanClass"
		}
	},

	events: {
		"click": "_onClick"
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.parse();
	},

	parse() {
		if (this.el) {
			this.set({
				title: this.el.getAttribute("data-title"),
				videoId: this.el.getAttribute("data-video-id")
			});
		}
		return this;
	},

	_onClick(e) {
		e.preventDefault();
		if (this.onSelect) {
			this.onSelect(this);
		}
	}

});

export default Component.extend({

	props: {
		onSelect: "function"
	},

	component: {
		type: PlaylistItem,
		bindings: {
			onSelect: "onSelect"
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.createItemComponents();
	},

	at(index) {
		return this.items ? this.items[index] : undefined;
	},

	getItemByVideoId(videoId) {
		if (this.items) {
			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].videoId === videoId) {
					return this.items[i];
				}
			}
		}
		return null;
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		this.createItemComponents();
		return this;
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		this.createItemComponents();
		return this;
	},

	createItemComponents() {
		const elements = this.el ? this.queryAllByHook("playlist-item") : null;
		const items = [];
		if (elements && elements.length) {
			for (let i = 0; i < elements.length; i++) {
				items.push(this.createComponent(assign({el: elements[i]}, this.component)));
			}
			this.items = items;
		}
		return this;
	}

});
