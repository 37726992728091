function add(array, value) {
	return array ? array.indexOf(value) === -1 ? array.concat(value) : array : [value];
}
exports.add = add;

var find = Array.prototype.find ? function (array, predicate, context) {
	return Array.prototype.find.call(array, predicate, context);
} : function (array, predicate, context) {
	if (array === null || array === undefined) {
		throw new TypeError("find called on null or undefined");
	}
	if (typeof predicate !== "function") {
		throw new TypeError("predicate must be a function");
	}
	context || (context = array);
	var list = Object(array);
	var length = list.length >>> 0;
	var i, value;
	for (i = 0; i < length; i++) {
		value = list[i];
		if (predicate.call(context, value, i, list)) {
			return value;
		}
	}
	return undefined;
};
exports.find = find;

function remove(array, value) {
	var index = array ? array.indexOf(value) : -1;
	return index !== -1 ? array.slice(0, index).concat(array.slice(index + 1)) : array;
}
exports.remove = remove;

function toggle(array, value) {
	if (array && array.indexOf(value) !== -1) {
		return remove(array, value);
	}
	return add(array, value);
}
exports.toggle = toggle;
