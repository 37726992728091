import MotionComponent, {travel} from "./motion";
import dom from "ampersand-dom";
import {createParallaxSet} from "../lib/parallax";

export default MotionComponent.extend({

	props: {
		activeElement: "element",
		onChange: "function"
	},

	events: {
		"click [data-hook~=\"stack-item\"]": "_onItemClick"
	},

	initialize() {
		MotionComponent.prototype.initialize.apply(this, arguments);
		this.on("change:activeElement", this._handleActiveElementChange);
		this.initParallaxes();
	},

	didMove(rect, data) {
		if (this.parallaxes) {
			this.parallaxes.draw(travel(rect, data.viewport), rect.width / 100 * 3);
		}
	},

	initParallaxes() {
		if (this.el) {
			const elements = this.queryAll("[data-parallax]");
			this.parallaxes = createParallaxSet(elements);
		}
		return this;
	},

	incrementElementZIndex(el) {
		const newZIndex = ((this._currentZIndex || 1) + 1);
		el.style.zIndex = newZIndex;
		this._currentZIndex = newZIndex;
		return this;
	},

	mount() {
		MotionComponent.prototype.mount.apply(this, arguments);
		this.initParallaxes();
		return this;
	},

	remove() {
		this.parallaxes = undefined;
		return MotionComponent.prototype.remove.apply(this, arguments);
	},

	render() {
		MotionComponent.prototype.render.apply(this, arguments);
		this.initParallaxes();
		return this;
	},

	toggleElementActiveState(el, state) {
		if (el) {
			dom[state ? "addClass" : "removeClass"](el, "isActive");
		}
		return this;
	},

	_handleActiveElementChange(target, newElement) {
		const prevElement = target.previous("activeElement");
		if (prevElement) {
			this.toggleElementActiveState(prevElement, false);
		}
		if (newElement) {
			this.toggleElementActiveState(newElement, true);
			this.incrementElementZIndex(newElement);
		}
		if (this.onChange) {
			this.onChange(newElement ? newElement.id : null);
		}
	},

	_onItemClick(e) {
		const target = e.delegateTarget || e.currentTarget;
		this.activeElement = target;
	}

});
