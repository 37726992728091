var Store = require("key-tree-store");
var getValue = require("./get-value");

function addBinding(store, path, handler) {
	store.add(path, handler);
	return function () {
		store.remove(handler);
	};
}

function createHandler(component, prop) {
	return function updateBoundStateProp(value) {
		if (component.isState) {
			if (value === undefined) {
				component.unset(prop);
			} else {
				component.set(prop, value);
			}
		} else {
			component[prop] = value;
		}
	};
}

module.exports = function createBindingsStore(context) {
	var store = new Store();

	function add(component, bindings) {
		var unsubscribers = [];

		function registerBinding(path, prop) {
			var handler = createHandler(component, prop);
			unsubscribers.push(addBinding(store, path, handler));
		}

		if (typeof bindings === "function") {
			bindings(function (path) {
				return {
					to: function (prop) {
						registerBinding(path, prop);
					}
				};
			});

		} else {
			for (var key in bindings) {
				if (bindings.hasOwnProperty(key)) {
					registerBinding(bindings[key], key);
				}
			}
		}

		return function unsubscribe() {
			for (var i = 0, len = unsubscribers.length; i < len; i++) {
				unsubscribers[i]();
			}
			unsubscribers = [];
		};
	}

	function update(path) {
		var handlers = store.getGrouped(path);
		for (var item in handlers) {
			if (handlers.hasOwnProperty(item)) {
				var value = getValue(context, item);
				for (var i = 0, len = handlers[item].length; i < len; i++) {
					handlers[item][i](value);
				}
			}
		}
	}

	return {
		add: add,
		update: update
	};
};
