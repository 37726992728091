import Component from "../lib/components/component";
import YouTubeVideo from "../lib/youtube/component";

export default Component.extend({

	props: {
		isPlaying: "boolean",
		onChangePlaying: "function"
	},

	derived: {
		videoId: {
			cache: false,
			fn() {
				return this.video.videoId;
			}
		}
	},

	components: {
		video: {
			type: YouTubeVideo,
			selector: "[data-hook~=\"responsive-video\"]",
			bindings: {
				onPlayingChange: "handlePlayingChange"
			}
		}
	},

	handlePlayingChange(isPlaying) {
		this.isPlaying = isPlaying;
		if (this.onChangePlaying) {
			this.onChangePlaying(isPlaying);
		}
	},

	loadVideoById(videoId) {
		this.video.videoId = videoId;
		if (!this.isPlaying) {
			this.play();
		}
		return this;
	},

	pause() {
		this.video.pause();
		return this;
	},

	play() {
		this.video.play();
		return this;
	},

	togglePlaying(state) {
		state = state === undefined || state === null ? !this.isPlaying : state;
		this[state ? "play" : "pause"]();
		return this;
	}

});
