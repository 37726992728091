import State from "ampersand-state";
import Component from "../lib/components/component";
import template from "./gallery-slide.hbs";
import {picturefillComponent} from "../lib/utils";

export default Component.extend({

	props: {
		model: "state",
		ratio: "number"
	},

	derived: {
		fitClassName: {
			cache: false,
			deps: ["model.ratio", "ratio"],
			fn() {
				const modelRatio = this.model && this.model.ratio || 0;
				return "GallerySlide--fit" +
						(this.ratio > modelRatio ? "Width" : "Height");
			}
		},
		sizes: {
			cache: false,
			deps: ["model.ratio", "ratio"],
			fn() {
				const modelRatio = this.model && this.model.ratio || 1;
				const contextRatio = modelRatio > this.ratio ? this.ratio / modelRatio : 1;
				return (contextRatio * 100) + "vw";
			}
		}
	},

	bindings: {
		fitClassName: {
			type: "class"
		}
	},

	template: template,

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this.listenTo(this, "change:model", this.rerender);
	},

	mount() {
		Component.prototype.mount.apply(this, arguments);
		picturefillComponent(this);
		return this;
	},

	renderTemplate(context, template) {
		if (context instanceof State) {
			context = context.serialize({ derived: true });
		}
		return Component.prototype.renderTemplate.call(this, context, template);
	},

	render() {
		Component.prototype.render.apply(this, arguments);
		picturefillComponent(this);
		return this;
	},

	rerender() {
		if (this.rendered) {
			this.render();
		}
		return this;
	}

});
