import get from "lodash/get";
import result from "lodash/result";

const isDefined = (value) => {
	return value !== undefined && value !== null;
};

export default {

	currentLocale: undefined,

	defaultLocale: "en",

	translations: {},

	urlRoot: "/api/translations",

	fetch(locale) {
		locale || (locale = this.locale());
		const base = result(this, "urlRoot");
		const url = base + (base.charAt(base.length - 1) === "/" ? "" : "/") + encodeURIComponent(locale);
		fetch(url, {credentials: "same-origin"}).then(response => {
			return response.json();
		}).then(json => {
			return this.set(json.locale, json.translations);
		});
	},

	find(locale) {
		locale || (locale = this.locale());
		if (this.translations.hasOwnProperty(locale)) {
			return Promise.resolve(this);
		}
		return this.fetch(locale);
	},

	get(key) {
		const locale = this.locale();
		const translations = locale && this.translations[locale] || {};
		if (isDefined(translations[key])) {
			return translations[key];
		}
		const value = key.indexOf(".") !== -1 ? get(translations, key) : null;
		return isDefined(value) ? value : key;
	},

	locale(locale) {
		if (locale) {
			this.currentLocale = locale;
		}
		return this.currentLocale || this.defaultLocale;
	},

	set(locale, translations) {
		this.translations[locale] = translations || {};
		this.locale(locale);
		return this;
	},

	translate(term, data) {
		const value = this.get(term);
		if (data) {
			return value.replace(/{(\w*)}/g, (m, key) => {
				return data.hasOwnProperty(key) ? data[key] : "";
			});
		}
		return value;
	}

};
