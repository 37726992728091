import Media from "./media";
import assign from "lodash/assign";

/**
Manages an `HTMLAudioElement` in the DOM.

@class Audio
@extends Media
**/
export default Media.extend({

	events: assign({
		"loadedmetadata": "_onLoadedmetadata"
	}, Media.prototype.events),

	/**
	The user agent has just determined the duration and dimensions of the media
	resource and the text tracks are ready.

	@private
	@method _onLoadedmetadata
	@param {Event} e
	**/
	_onLoadedmetadata() {
		this.set({
			duration: this.el.duration
		}, {src: "UI"});
	},

});
