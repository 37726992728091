import MotionComponent, {travel} from "./motion";
import {createParallaxSet} from "../lib/parallax";
import {createResponsiveBackgroundSet} from "../lib/image";

export default MotionComponent.extend({

	initialize() {
		MotionComponent.prototype.initialize.apply(this, arguments);
		this.initBackgroundImages();
		this.initParallaxes();
	},

	didMove(rect, data) {
		if (this.parallaxes) {
			this.parallaxes.draw(travel(rect, data.viewport), rect.height / 100 * 2.5);
		}
		if (this.images) {
			this.images.update(rect.width, rect.height);
		}
	},

	initBackgroundImages() {
		const elements = this.el ? this.queryAll("[data-srcset]") : null;
		if (elements && elements.length) {
			const {width} = this.motionManager.getRectForComponent(this) || {};
			this.images = createResponsiveBackgroundSet(elements);
			this.images.resize(width);
		}
		return this;
	},

	initParallaxes() {
		const elements = this.el ? this.queryAll("[data-parallax]") : null;
		if (elements && elements.length) {
			this.parallaxes = createParallaxSet(elements);
		}
		return this;
	},

	mount() {
		MotionComponent.prototype.mount.apply(this, arguments);
		this.initBackgroundImages();
		this.initParallaxes();
		return this;
	},

	remove() {
		this.images = undefined;
		this.parallaxes = undefined;
		return MotionComponent.prototype.remove.apply(this, arguments);
	},

	render() {
		MotionComponent.prototype.render.apply(this, arguments);
		this.initBackgroundImages();
		this.initParallaxes();
		return this;
	}

});
