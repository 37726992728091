import Component from "../lib/components/component";
import FilterMany from "./filter-many";
import assign from "lodash/assign";
import {generateUrl} from "../lib/utils";

export default Component.extend({

	props: {
		action: "string",
		activeFilterGroup: "state",
		queryParams: "object"
	},

	components: {
		types: {
			type: FilterMany,
			container: "[data-hook~=\"types\"]",
			bindings: {
				onToggle: "_handleToggle",
				onValueChange: "_handleValueChange"
			}
		},
		decades: {
			type: FilterMany,
			container: "[data-hook~=\"decades\"]",
			bindings: {
				onToggle: "_handleToggle",
				onValueChange: "_handleValueChange"
			}
		}
	},

	initialize() {
		Component.prototype.initialize.apply(this, arguments);
		this._onOutsideClick = this._onOutsideClick.bind(this);
		this.on("change:activeFilterGroup", this._handleActiveFilterGroupChange);
		this.on("change:queryParams", this.sendChangeFilter);
		this.parseProps();
	},

	parseProps() {
		const action = this.el && this.el.action;
		if (action) {
			this.action = action;
		}
		return this;
	},

	toggleFilterGroup(filterGroup, state) {
		if (filterGroup) {
			state = state == null || state == undefined ? !filterGroup.isExpanded : state;
			filterGroup.isExpanded = state;
		}
		return this;
	},

	_handleActiveFilterGroupChange(target, newValue) {
		const prevValue = this.previous("activeFilterGroup");
		if (prevValue) {
			if (!newValue) {
				document.removeEventListener("click", this._onOutsideClick, false);
			}
			this.toggleFilterGroup(prevValue, false);
		}
		if (newValue) {
			this.toggleFilterGroup(newValue, true);
			if (!prevValue) {
				document.addEventListener("click", this._onOutsideClick, false);
			}
		}
	},

	sendChangeFilter() {
		return this.send("changeFilter", generateUrl(this.action, this.queryParams));
	},

	_handleToggle(filterGroup) {
		if (this.activeFilterGroup === filterGroup) {
			this.unset("activeFilterGroup");
		} else {
			this.activeFilterGroup = filterGroup;
		}
	},

	_handleValueChange(name, value) {
		const newValues = {};
		newValues[name] = value.map(item => item.value);
		this.queryParams = assign({}, this.queryParams, newValues);
	},

	_onOutsideClick(e) {
		const activeFilterGroup = this.activeFilterGroup;
		if (activeFilterGroup && !activeFilterGroup.el.contains(e.target)) {
			this.unset("activeFilterGroup");
		}
	}

});
